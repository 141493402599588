import React from 'react';

import { makeStyles } from '~/@components/@theme';

import { DownloadRandomReceipts } from './DownloadRandomReceipts';
import { RecalculateSolidTestResults } from './RecalculateSolidTestResults';
import { TestBugsnagButton } from './TestBugsnagButton';
import { TestOnly } from './TestOnly';

export const Database = () => {
    const { root } = useStyles();

    return (
        <div className={root}>
            <RecalculateSolidTestResults />
            <TestOnly />
            <DownloadRandomReceipts />
            <TestBugsnagButton />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        width: 400,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));
