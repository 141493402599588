import axios from 'axios';
import React from 'react';

import { ProgressButton } from '~/@components/Button/ProgressButton';
import { bugsnagTestUrl } from '~/apolloClient';
import i18n from '~/i18n';
import { getAxiosConfig } from '~/utils/axios';

export const TestBugsnagButton = () => {
    const onClick = () =>
        axios
            .post(bugsnagTestUrl, {}, getAxiosConfig({}, 'json'))
            .then(res => alert(JSON.stringify(res.data, null, 2)))
            .catch(error => alert(String(error)));

    return (
        <ProgressButton variant="contained" color="primary" onClick={onClick}>
            {i18n.settingsPage.database.testBugsnag}
        </ProgressButton>
    );
};
