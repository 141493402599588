import { mapValues } from 'lodash';
import { $Values } from 'utility-types';

import { DELIVERY_LINE_VIEW_MODE, LANDFILL_VIEW_MODE, PROJECT_VIEW_MODE } from './viewModes';

// API ENDPOINTS - SERVERSIDE ROUTES

export const apiEndpoints = {
    api: '/api',
    graphql: '/graphql',
    ping: '/ping',
    timeout: '/timeout',
    bugsnagTest: '/bugsnag-test',
    login: '/login',
    signup: '/signup',
    forgot: '/forgot',
    confirm: '/confirm/:link',
    projectDeviations: '/project-deviation',
    projectAnalysis: '/project/analysis',
    projectDeliveryReceipts: '/project/delivery-receipts',
    landfillDeviations: '/landfill-deviation',
    landfillCertificates: '/landfill-cert',
    landfillDeclarations: '/landfill-declarations',
    landfillDeliveryReceipts: '/landfill/delivery-receipts',
    landfillEventPhotos: '/landfill/delivery-photos',
    deliveryLineReceipt: '/delivery-receipt',
    deliveryLineReceiptPdf: '/delivery-receipt-pdf',
    deliveryLinesRandom: '/delivery-receipts-random',
    projectReceiptsXlsx: '/project/delivery-receipt-xlsx',
    landfillReceiptsXlsx: '/landfill/delivery-receipt-xlsx',
    voyager: '/voyager',
    schema: '/schema',
    introspection: '/introspection',
    schemaExpressRoutes: '/schemaExpressRoutes',
    declarationTempl: '/declaration-templ',
    declarationTemplView: '/declaration-templ/view',
    deleteTestUsers: '/delete-test-users',
    cacheStats: '/cache/stats',
    cacheClear: '/cache/clear',
    certificates: '/certificates',
    cert: '/cert',
    invoices: '/invoices',
    orderExportLines: '/order-export-lines',
    publicSettings: '/public-settings',
    databaseSwitchToTest: '/database/switch-to-test',
    databaseResetTest: '/database/reset-test',
    databaseSwitchToBranch: '/database/switch-to-branch',
    databaseGenerateFakeFiles: '/database/generate-fake-files',
    mobileForgot: '/mobile-forgot',
    mobileRegisterStatus: '/mobile-register-status',
    mobileRegister: '/mobile-register',
    mobileLogin: '/mobile-login',
    mobileForgotSend: '/mobile-forgot-send',
    mobileForgotVerify: '/mobile-forgot-verify',
    mobileForgotReset: '/mobile-forgot-reset',
    content: '/content/:filename',
} as const;

// FRONTEND ROUTES AND UTILITIES

export function replaceRouteParams(route: string, params: Record<string, string> = {}): string {
    for (const k of Object.keys(params)) {
        route = route.replace(`:${k}`, params[k]!);
    }

    return route;
}

export const fullRouteWith = (baseUrl: string, route: string) => baseUrl.replace(/\/$/, '').concat(route);

const getFullRoutes = <TObj extends {}>(
    baseRoute: string,
    routes: { [TKey in keyof TObj]: string[] }
): { [TKey in keyof TObj]: string } =>
    mapValues(routes, (routeParts: string[]) => ['', baseRoute, ...routeParts].join('/'));

const ID = ':id';

const sochiFullRoutes = getFullRoutes('layouts', {
    base: [],
    projectDetail: ['projects', ':projectId'],
    projectPriceCalculator: ['projects', ':projectId', 'price-calculator'],
    projectHistory: ['projects', ':projectId', 'history'],
    projectMassDetail: ['projects', ':projectId', 'history', ':massId'],
    projectInfo: ['projects', ':projectId', 'info'],
    projectSchedule: ['projects', ':projectId', 'schedule'],
    projectDocuments: ['projects', ':projectId', 'documents'],
    projectDeviations: ['projects', ':projectId', 'deviations'],
    projectUsers: ['projects', ':projectId', 'users'],
    projectAI: ['projects', ':projectId', 'ai'],
    projectDeliveries: ['projects', ':projectId', 'deliveries'],
    projectDashboard: ['projects', ':projectId', 'dashboard'],
    landfillDetail: ['landfills', ':landfillId'],
    landfillOverview: ['landfills', ':landfillId', 'overview'],
    landfillSchedule: ['landfills', ':landfillId', 'schedule'],
    landfillInfo: ['landfills', ':landfillId', 'info'],
    landfillDeviations: ['landfills', ':landfillId', 'deviations'],
    landfillMaterials: ['landfills', ':landfillId', 'materials'],
    landfillDocumentation: ['landfills', ':landfillId', 'documentation'],
    landfillAi: ['landfills', ':landfillId', 'ai'],
    landfillDeliveries: ['landfills', ':landfillId', 'deliveries'],
    landfillDeliveriesForMass: ['landfills', ':landfillId', 'deliveries', ':massSerialNumber'],
    offers: ['offers'],
    offerOutgoings: ['offers', 'outgoing'],
    offerOutgoing: ['offers', 'outgoing', ':projectId'],
});

export type ISochiFullRoutes = $Values<typeof sochiFullRoutes>;

const adminFullRoutes = getFullRoutes('admin', {
    base: [],
    allLandfills: [LANDFILL_VIEW_MODE.ALL_LANDFILLS],
    activeLandfills: [LANDFILL_VIEW_MODE.ACTIVE_LANDFILLS],
    landfill: ['landfills', ID],
    subareas: [LANDFILL_VIEW_MODE.SUBAREAS],
    allProjects: [PROJECT_VIEW_MODE.ALL_PROJECTS],
    activeProjects: [PROJECT_VIEW_MODE.ACTIVE_PROJECTS],
    project: ['projects', ID],
    ordersInProgress: ['volumes', 'in-progress'],
    ordersDone: ['volumes', 'done'],
    deliveryLines: [DELIVERY_LINE_VIEW_MODE.DELIVERY_LINES],
    companies: ['companies'],
    company: ['companies', ID],
    users: ['users'],
    user: ['users', ID],
    dumpTypes: ['dump-types'],
    beastMaterials: ['beast-materials'],
    landfillStatuses: ['landfill-statuses'],
    substances: ['substances'],
    toxicLimits: ['toxic-limits'],
    settings: ['settings'],
    account: ['account'],
    dumpLoadRequested: ['dump-loads', 'requested'],
    dumpLoadConfirmed: ['dump-loads', 'confirmed'],
    dumpLoadRejected: ['dump-loads', 'rejected'],
    dumpLoadOrdered: ['dump-loads', 'ordered'],
    dumpLoadAllDumpLoads: ['dump-loads'],
    blacklist: ['blacklist'],
    permissions: ['permissions'],
    releaseNotes: ['release-notes'],
    despatchAdviceResponses: ['despatch-advice-responses'],
    vehicleTypes: ['vehicle-types'],
    timeSpent: ['time-spent'],
});

export const invalidLoginRoutes = {
    notApproved: '/not-approved',
    userBlocked: '/user-blocked',
    notConfirmedEmail: '/not-confirmed-email',
} as const;

export type TInvalidLoginRoutes = $Values<typeof invalidLoginRoutes>;

export const routes = {
    root: '/',
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot-password',
    notFound: '/not-found',
    confirmEmailID: '/confirm/:id',
    admin: adminFullRoutes,
    sochi: sochiFullRoutes,
    ...invalidLoginRoutes,
} as const;

export const startPages = {
    account: routes.admin.account,
    homepage: routes.root,
};

export const adminRoutes = routes.admin;
