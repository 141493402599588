import {
    BeastStatus,
    DeliveryLineStatus,
    DespatchAdviceCategory,
    DespatchAdviceResponseType,
    DespatchAdviceType,
    DeviationStatus,
    LandfillSubareaFields,
    OrderStatus,
    SignDocumentStatuses,
    SyncStatus,
    UnitOfMeasure,
    UserContextRole,
    UserRole,
} from '../../enums';
import type { I18n } from '../interface/i18n.interface';

export const sv: I18n = {
    preview: 'Förhandsvisning',
    pinpointerAb: 'Pinpointer AB',
    week: 'Vecka',
    hiddenAmountsForDeletedSubstances: 'Det finns borttagna ämnen med dolda halter: ',
    export: 'Exportera',
    pdf: 'Pdf',
    excel: 'Excel',
    word: 'Word',
    perPage: 'Per sida:',
    ContaminationLevelButton: {
        serverResult: 'Senast resultat: ',
    },
    NotFoundPage: {
        title: '404',
        subTitle: 'Hoppsan! Denna sidan hittades inte!',
        description: 'Sidan du letar efter kan ha tagits bort, namnet har ändrats eller är tillfälligt otillgänglig.',
        backToHome: 'Tillbaka till startsidan',
    },
    UserEditPage: {
        user: 'Användare',
        userType: 'Roll',
        registeredAt: 'Registrerad',
        status: 'Status',
        isBlocked: 'Låst',
        state: 'Stat',
        active: 'Aktiva',
        blocked: 'Blockerad',
        name: 'Förnamn',
        surname: 'Efternamn',
        email: 'e-post adress',
        phoneNumber: 'Telefonnummer',
        role: 'Roll',
        orgName: 'Organisationsnamn',
        orgNr: 'Organisationsnummer',
        customer: 'Kund',
        customerCompanyIsEmpty: 'Inget företag hittades, försök att synkronisera företag under order',
        updateSuccess: 'Användarinformation uppdaterades',
        notifyUserWithEmail: 'Meddela användare med e-post',
        nameIsRequired: 'Namn krävs',
        roleIsRequired: 'Roll krävs',
        customerIsRequired: 'Kund krävs',
        resetPasswordConfirmation: 'Är du säker på att du vill återställa lösenordet?',
        newPasswordHasBeenSentTo: 'Nytt lösenord har skickats till $1',
        addLandfill: 'Lägg Till Mottagning',
        addProject: 'Lägg Till Projekt',
        deleteUserLandfillConfirmation: 'Är du säker på att du vill ta bort användarens mottagning?',
        deleteUserProjectConfirmation: 'Är du säker på att du vill ta bort användarens projekt?',
        noDataFound: 'Ingen information hittad',
        vehicles: 'Fordon',
        vehiclesEmptyList: 'Det finns inga fordon här än',
        licensePlate: 'Registreringsskylt',
        licensePlateShouldBeUnique: 'Registreringsskyltar måste vara unika',
        capacity: 'Kapacitet (t.)',
        vehicleType: 'Fordonstyper',
        fuel: 'Bränsle',
        engine: 'Motor',
        addVehicle: 'Lägg till fordon',
    },
    DriverCard: {
        driver: 'Förare',
        status: 'Status',
        registeredAt: 'Registrerad',
        state: 'Stat',
        name: 'Namn',
        phoneNumber: 'Telefonnummer',
        email: 'e-post adress',
        trucks: 'Lastbilar',
        trucksEmptyList: 'Det finns inga lastbilar här än',
        licensePlate: 'Registreringsskylt',
        licensePlateShouldBeUnique: 'Registreringsskyltar måste vara unika',
        capacity: 'Kapacitet (t.)',
        addTruck: 'Lägg till lastbil',
        exitWithoutChanges: 'Avsluta utan ändringar',
        thereAreUnsavedChanges: 'Det finns ändringar som inte har sparats.\nÄr du säker på att du vill avsluta?',
        projects: {
            project: 'Projekt',
            deleteDriverProjectConfirmation: 'Är du säker på att du vill ta bort projektet?',
            noDataFound: 'Ingen information hittad',
            addProject: 'Lägg Till Projekt',
        },
    },
    ConfirmEmailPage: {
        error: 'Något gick fel...',
        notFound: 'Användaren hittades inte.',
        linkExpired: 'Din länk har gått ut. Vänligen registrera dig igen.',
        complete: 'Din e-post är bekräftad. Vänligen invänta aktivering av ditt konto.',
    },
    NotConfirmedEmailPage: {
        info: 'Din e-post är inte bekräftad. Vänligen bekräfta e-post. Kontrollera din låda för skräppost(spam) om du inte hittar mailet',
    },
    NotApprovedPage: {
        notApproved: 'Ditt konto är inte aktiverat ännu, vänligen försök igen senare.',
    },
    UserBlockedPage: {
        userBlocked: 'Ditt konto är Låst.',
    },
    SignupPage: {
        error: 'Något gick fel...',
        userExists: 'Ett konto med samma e-post finns redan.',
        userBlacklisted: 'Kontot med den här e-postadressen är blockerad.',
        limitError: 'Du har överstigit din dagliga gräns för antal registreringar',
        registrationIsSuccessful:
            'Du har registrerats. För att bekräfta registreringen, vänligen kontrollera din e-post och bekräfta din e-postadress.',
        refuse: 'Avböj',
        agree: 'Godkänn',
        acceptTermsNote: 'Jag har läst och godkänt',
        termsAndConditions: 'användarvillkor och regler',
        pleaseAcceptTermsNote: 'Vänligen bekräfta att Ni har läst och godkänt',
        pleasePassCaptcha: 'Vänligen slutför captcha',
        termsHeader: 'ALLMÄNNA VILLKOR - PINPOINTER',
        termsSubheader: '("Allmänna Villkor")',
        terms: [
            {
                header: 'INLEDNING',
                content: [
                    'Dessa Allmänna Villkor avser tillhandahållandet av Applikationen.',
                    'Dessa Allmänna Villkor är inte tillämpliga för Pinpointers övriga tjänster, t.ex. avsättning av restprodukter. För samtliga övriga tjänster som tillhandhålls av Pinpointer gäller separat avtal.',
                ],
            },
            {
                header: 'DEFINITIONER',
                content: [
                    'I dessa Allmänna Villkor skall följande termer och uttryck ha den betydelse som anges nedan:',
                    '"Allmänna Villkor" betyder dessa allmänna villkor jämte samtliga härtill hörande bilagor;',
                    '"Applikationen" betyder den webb- och mobilapplikation som tillhandahålls i enlighet med dessa Allmänna Villkor, enligt vad som närmare beskrivs i Bilaga 1, Tjänstebeskrivning;',
                    '"Slutanvändare" betyder en unik användare av Applikationen;',
                    '"Slutanvändarkonto" betyder det unika användarkonto som ska upprättas för varje unik användare av Applikationen.',
                ],
            },
            {
                header: 'PINPOINTERS SKYLDIGHETER',
                content: [
                    'Pinpointer ska tillhandahålla Applikationen i enlighet med dessa Allmänna Villkor och säkerställa att Applikationen fungerar i enlighet med Bilaga 1, Tjänstebeskrivning, samt i enlighet med vad som i övrigt följer av dessa Allmänna Villkor.',
                ],
            },
            {
                header: 'ANVÄNDNING AV APPLIKATIONEN',
                content: [''],
            },
            {
                header: 'Slutanvändarkonton',
                content: [
                    'Varje Slutanvändarkonto ska vara hänförligt till en unik Slutanvändare. Det är av spårbarhetsskäl inte tillåtet att flera personer använder samma Slutanvändarkonto. För det fall Pinpointer upptäcker att flera personer använder ett Slutanvändarkonto, förbehåller sig Pinpointer rätten att stänga av tillgången till Applikationen.',
                    'Kunden kan själv skapa och ta bort Slutanvändarkonton. Assistans vid skapandet av Slutanvändarkonton tillhandhålls av supporten.',
                ],
            },
            {
                header: 'Kundens ansvar',
                content: [
                    'Kunden ansvarar för att de uppgifter som anges i Applikationen är korrekta och sanningsenliga samt att den kemianalys som ligger till grund för de uppgifter som förs in i Applikationen, är ut-förd av en ackrediterad tredje part. Pinpointer tar inte något ansvar för eventuella inkorrekta, icke uppdaterade eller annars felaktiga uppgifter som förs in i Applikationen.',
                    'Kunden är medveten om att de priser som genereras i Applikationen genom de uppgifter som Kunden anger i Applikationen kan komma att förändras efter att uppgifterna har kontrollerats av Pinpointer. Det pris som meddelas till Kunden efter sådan kontroll är det pris som gäller.',
                    'Innan några uppgifter kan registreras i Applikationen, ska Slutanvändaren bekräfta att de angivna uppgifterna är korrekta.',
                    'Kunden ska försvara och hålla Pinpointer skadeslöst för samtliga krav (inklusive ombudskostnader) från tredje part som riktas mot Pinpointer som ett resultat av att Kunden har angivit inkorrekta, icke uppdaterade eller annars felaktiga uppgifter.',
                    'Kunden åtar sig att följa samtliga relevanta lagar, förordningar, föreskrifter och andar författningar som har koppling till Kundens och dess Slutanvändares användning av Applikationen.',
                ],
            },
            {
                header: 'DRIFTSÅTAGANDE',
                content: [
                    'Pinpointer ska verka för att Applikationen är tillgänglig för Kunden och Slutanvändarna dygnet runt under årets alla dagar. Pinpointer har dock rätt att när som helst och utan föregående meddelande stänga av Kundens och Slutanvändarnas tillgång till Applikationen för underhåll, för att skydda Applikationen från obehöriga angrepp eller liknande eller för att vidta andra åtgärder som är påkallade av tekniska, drifts- och säkerhetsmässiga skäl. Pinpointer ska dock, så långt möjligt, informera Kunden om när sådana avbrott kan komma att äga rum.',
                    'Kunden har inte rätt till någon ersättning för skada (inklusive förlorad inkomst) som uppkommer på grund av att Applikationen inte är tillgänglig.',
                    'Applikationen ska finnas tillgängligt både via webb- och mobilapplikation. Kunden är medveten om att mobilapplikationen utgör en spegling av webbversionen av Applikationen, enligt vad som närmare beskrivs i Bilaga 1, Tjänstebeskrivning.',
                ],
            },
            {
                header: 'SUPPORT OCH UNDERHÅLL',
                content: [
                    'Pinpointer utvecklar och uppdaterar Applikationen löpande. I den mån Pinpointer genomför en väsentlig uppdatering av Applikationen, kommer Kunden att informeras innan en sådan väsentlig uppdatering genomförs, inklusive information om (i den mån möjligt) hur länge Applikationen förväntas vara otillgänglig (om alls) på grund av uppdateringen.',
                    'Pinpointer tillhandahåller första linjens support till Kunden och Slutanvändare, via e-post, telefon och chatt i Applikationen, under helgfria vardagar 08.00 - 16.00. Genom supporten kan Kunden eller en Slutanvändare får svar på enklare frågor rörande användningen av Applikationen samt anmäla eventuella fel eller brister som uppdagas i samband med användningen av Applikationen.',
                    'För det fall ett supportärende inte går att lösa genom första linjens support, eskalerar Pinpointer frågan och meddelar Kunden om att ett supportärende har upprättats hos Pinpointer. Pinpointer lämnar dock inga utfästelser rörande när ett supportärende kan vara hanterat, men åtar sig att hantera ärendet så skyndsamt som möjligt.',
                ],
            },
            {
                header: 'IMMATERIELLA RÄTTIGHETER',
                content: [
                    'Samtliga immateriella rättigheter som ägs av Pinpointer, inklusive utan begränsning namnrättigheter, kännetecken, varumärken (såväl registrerade som oregistrerade), upphovsrätter (inklusive källkod), mjukvarulicenser eller patent, ska fortsatt ägas av Pinpointer och får inte användas av Kunden på något annat sätt än vad som uttryckligen och skriftligen godkänns av Pinpointer. Inget i dessa Allmänna Villkor ska tolkas som en överlåtelse av Pinpointers immateriella rättigheter.',
                    'Samtliga immateriella rättigheter som återfinns i eller skapas som ett resultat av användningen av Applikationen, ska ägas av och/eller tillfalla Pinpointer.',
                    'Kunden erhåller genom dessa Allmänna Villkor en icke-exklusiv och under avtalstiden begränsad rätt att använda Applikationen i dess avsedda ändamål samt i enlighet med dessa Allmänna Villkor. Kunden får inte använda Applikationen (i) för något bedrägligt eller annars olag-ligt ändamål, (ii) på ett sätt som är avsett att störa driften av Applikationen, (iii) för att utreda, ändra, anpassa, översätta, "reverse engineer", dekompliera eller nedmontera någon del av Applikationen, inklusive utan begränsning dess objektkod eller källkod, (iv) för att kopiera, återge, sälja eller på annat sätt exploatera Applikationen eller dess innehåll, (v) för att avlägsna eventuella noteringar avseende upphovsrätt, varumärken eller annan äganderätt från Applikationen eller material som återfinns i Applikationen, eller (vi) för att överföra eller på annat sätt introducera virus, mask, trojaner, eller annan skadlig programvara eller kod, eller på annat sätt skada eller negativt påverka Applikationens funktionalitet eller tillgänglighet.',
                    'Detta avsnitt ska fortsätta att tillämpas även efter att Avtalet har upphört att gälla.',
                ],
            },
            {
                header: 'PERSONUPPGIFTER',
                content: [
                    'Pinpointer är personuppgiftsansvarig för den behandling av Slutanvändarens personuppgifter som sker när Pinpointer administrerar Slutanvändarkonton. För det fall att Kunden skapar Slutanvändarkonton åt sina anställa, uppdragstagare eller andra individer, ska Kunden vara personuppgiftsansvarig för den behandlingen som krävs för att skapa dessa Slutanvändarkonton. Detta påverkar dock inte Pinpointers personuppgiftsansvar i förhållande till administrationen av Slutanvändarkonton.',
                    'Parterna åtar sig, i Parternas respektive roller som självständigt personuppgiftsansvariga, att lämna vederbörlig information till registrerade och i övrigt följa de regler som gäller behandling av personuppgifter i enlighet med tillämplig dataskyddslagstiftning, inklusive GDPR (EU) 2016/679.',
                ],
            },
            {
                header: 'SEKRETESS',
                content: [
                    'Parterna förbinder sig att behandla all information och dokumentation som har samband med dessa Allmänna Villkor och användningen av Applikationen, inklusive all information om den andra Partens verksamhet som den ena Parten kan få tillgång till inom ramen för dessa Allmänna Villkor och användingen av Applikationen, strikt konfidentiellt och inte röja sådan information till någon tredje part utan den andra Partens skriftliga godkännande. Detta åtagande ska dock inte tillämpas i förhållande till sådan information som Part är skyldig att röja enligt lag, förordning eller myndighetsbeslut, varvid den Part som åläggs sådan skyldighet omedelbart ska meddela den andra Parten när sådan skyldighet föreligger. Sekretess enligt detta avsnitt 8 ska gälla under hela avtalstiden och för en tid om fem (5) år därefter.',
                ],
            },
            {
                header: 'ANSVAR',
                content: [
                    'Pinpointer ska endast hållas ansvarig för sådana direkta skador som åsamkas Kunden till följd av Pinpointers brott mot dessa Allmänna Villkor. Pinpointer ska inte hållas ansvarig för indirekta skador, inklusive utan begränsning förlorad inkomst, förlorade affärsmöjligheter eller annan ekonomisk följdskada, om inte skadan har uppkommit till följd av Pinpointers grova vårdslöshet eller uppsåt.',
                    'Utan att det påverkar tillämpningen av punkten ovan ska Pinpointers sammanlagda ansvar enligt Avtalet och dessa Allmänna Villkor under ett (1) år inte överskrida ett (1) prisbasbelopp enligt Socialförsäkringsbalk (2010:110).',
                ],
            },
            {
                header: 'ÖVRIGT',
                content: [''],
            },
            {
                header: 'Överlåtelse',
                content: [
                    'Kunden ska inte äga rätt att helt eller delvis överlåta sin rätt att använda Applikationen (inklusive övriga rättigheter och skyldigheter som följer av dessa Allmänna Villkor) till någon annan part utan Pinpointers skriftliga godkännande.',
                ],
            },
            {
                header: 'Ändringar och tillägg',
                content: [
                    'Pinpointer har efter eget gottfinnande rätt att ändra eller göra tillägg till dessa Allmänna Villkor. Skulle en ändring medföra att Applikationen inte längre tillhandahålls kostnadsfritt, kommer Kunden att meddelas om detta i förväg.',
                ],
            },
            {
                header: 'TILLÄMPLIG LAG OCH TVIST',
                content: [
                    'Dessa Allmänna Villkor ska tolkas och tillämpas i enlighet med svensk lag, utan iakttagande av dess principer för lagval.',
                    'Tvist i anledning av dessa Allmänna Villkor ska i första hand försöka lösas genom förtroliga diskussioner mellan Parterna. Om inte detta låter sig göras ska tvisten slutligt avgöras genom skiljedom vid Stockholms Handelskammares Skiljedomsinstitut ("Institutet"). Institutets Regler för Förenklat Skiljeförfarande ska gälla om inte Institutet med beaktande av målets svå-righetsgrad, tvisteföremålets värde och övriga omständigheter bestämmer att Regler för Stockholms Handelskammares Skiljedomsinstitut ska tillämpas på förfarandet. I sistnämnda fall ska Institutet också bestämma om skiljenämnden ska bestå av en eller tre skiljemän. Skil-jeförfarande ska äga rum i Stockholm och ske på svenska. Skiljeförfaranden som äger rum enligt denna punkt, inklusive all information som avslöjas och all dokumentation som inges eller utfärdas av eller på uppdrag av endera Part eller av skiljenämnden, samt alla beslut och skilje-domar som fattas eller meddelas i samband med skiljeförfarandet, ska vara strikt konfidentiella och inte användas för andra ändamål än skiljeförfarandet i fråga och inte heller röjas till tredje man utan föregående skriftlig överenskommelse mellan Parterna.',
                ],
            },
        ],
        register: 'Registrera',
    },
    ForgotPasswordPage: {
        resetSuccess:
            'En länk har skickats till den angivna e-postadressen. Följ beskrivningen för att återställa lösenordet.',
        resetFailed: 'Fel vid återställning av lösenord',
        Submit: 'Skicka',
    },
    Copyable: {
        copy: 'kopiera',
        copied: 'kopieras',
    },
    leaveMessage: 'Det finns ej ännu sparade ändringar, är du säker på att du vill avbryta?',
    back: 'Tillbaka',
    noElements: 'Inga mottagare',
    city: 'Stad',
    address: 'Adress',
    postalCode: 'Postnummer',
    addressLine1: 'Adressrad #1',
    addressLine2: 'Adressrad #2',
    of: 'of',
    allowed: 'tillåten',
    notAllowed: 'inte tillåtet',
    elementsCount: 'Visa antal',
    noCertificates: '[Inga Dokument]',
    search: 'Sök',
    projectTestresult: 'Projekt - Testresultat',
    noProjectsFound: 'Inga projekt hittades',
    noLandfillsFound: 'Inga mottagare hittades',
    subarea_allowFA: 'FA tillåtet',
    fill: 'Fyll i',
    on: 'JA',
    off: 'NEJ',
    subarea_associationLevel: 'Fyll i värden enligt föroreningsnivå',
    none: 'INGA',
    subarea_toxic: 'Gränsvärden för delområden',
    unnamed: '[Namnlös]',
    subareaFields: {
        [LandfillSubareaFields.maxAmount]: 'Maxbelopp',
        [LandfillSubareaFields.currentAmount]: 'Nuvarande beloppet',
        [LandfillSubareaFields.reservedAmount]: 'Reserverat belopp',
        [LandfillSubareaFields.deliveredAmount]: 'Levererat belopp',
        [LandfillSubareaFields.priceBase]: 'Grundpris',
        [LandfillSubareaFields.priceOver50cm]: 'Pris över 50 cm',
        [LandfillSubareaFields.priceNotStackable]: 'Ej stapelbart pris',
        [LandfillSubareaFields.solidState]: 'Fast tillstånd',
        [LandfillSubareaFields.leachingState]: 'Lakningstillstånd',
        [LandfillSubareaFields.allowSolidFA]: 'Är tillåta fast FA',
        [LandfillSubareaFields.onHold]: 'Är på is',
        [LandfillSubareaFields.unitOfMeasure]: 'Måttenhet',
        [LandfillSubareaFields.comment]: 'Kommentar',
    },
    subarea: 'Delområde',
    subareas: 'Delområden',
    subarea_name: 'Beteckning',
    subarea_capacity: 'Kapacitet',
    subarea_price: 'Pris',
    subarea_maxCapacity: 'Max kapacitet',
    subarea_currentCapacity: 'Nyttjad kapacitet',
    subarea_pricePerUnit: 'Pris per enhet',
    subarea_create: 'SKAPA NYTT DELOMRÅDE',
    subarea_stackable: 'Stapelbar',
    subarea_over50cm: 'Över 50cm',
    pleaseFillPrint: 'Ange antal dokument att skriva ut!',
    nettPrice: 'Nettopris',
    downloadInProgress: 'Nedladdning pågår. Vänta!',
    printed: 'Utskriven',
    print: 'Skriv ut antal',
    selectTemplate: 'Välj mall...',
    templates: 'Mallar',
    closedTo: 't.o.m. datum',
    closedFrom: 'fr.o.m. datum',
    show: 'Visa',
    showAll: 'Visa allt',
    calculate: 'Beräkna',
    prices: 'Priser',
    download: 'Ladda ner',
    app: {
        name: {
            pinpointer: 'Pinpointer',
        },
        title: {
            pinpointer: 'PINPOINTER',
        },
    },
    send: 'Skicka',
    contamination: 'Föroreningsgrad',
    marginPercent: 'Marginal(%)',
    hide: 'Dölj',
    clear: 'Rensa',
    filter: 'Filtrera',
    testMethod: 'Testmetod',
    uploadComplete: 'Uppladdning genomförd',
    declarationOfWaste: 'Avfallsdeklaration',
    scaleReceiptScan: 'Vågkvitto scan',
    fileTemplates: 'Filmallar',
    standardLandfill: 'Mall',
    emptyLandfill: 'Tom',
    editTemplate: 'Redigera mall',
    contaminationType: 'Föroreningstyp',
    contaminationLevel: 'Föroreningsnivå',
    contaminationLevelUnavailable: 'Föroreningsnivån är inte tillgänglig',
    deleteCertificate: 'Ta bort dokument',
    defaultContaminationType: 'Standard föroreningstyp',
    defaultContaminationLevel: 'Standard föroreningsnivå',
    addDumpLoad: 'Lägg till typ av Massa',
    addLandfill: 'Lägg Till Mottagning',
    addProject: 'Lägg Till Projekt',
    addDriver: 'Lägg till förare',
    addSpecialValue: 'Skapa specialvärde',
    addMaximumValue: 'Skapa maximalt värde',
    addCapacity: 'Skapa kapacitet',
    addComment: 'Lägg till kommentar',
    solids: 'Totalhalt',
    leaching: 'Laktest',
    createdFrom: 'Skapad från',
    createdTo: 'Skapat till',
    certificate: 'Dokument',
    upload: 'Ladda upp',
    replace: 'Byta ut',
    view: 'Visa',
    onlyMyProjects: 'Endast Mina Projekt',
    receiver: 'Mottagare',
    receivers: 'Mottagarens',
    sender: 'AVSÄNDARE-TO-REMOVE',
    senders: 'Användare',
    specialSubstances: 'Särskilda värden',
    substancesMaxLevels: 'Maxvärden',
    doYouReallyWantRecover: 'Vill du verkligen återställa?',
    doYouReallyWantDestroy: 'Är du säker på att ta bort?',
    toTrash: 'Radera',
    recover: 'Återställ',
    forgotPassword: 'Glömt lösenord?',
    newPasswordSet: 'Nytt lösenord sparat',
    submit: 'Lämna',
    resetItNow: 'Återställ nu',
    signup: 'Registrera nytt konto',
    register: 'Registrera',
    hideNA: 'Göm N/A',
    result: 'Resultat',
    NA: 'N/A',
    toxicOk: 'OK',
    toxicFail: 'FEL',
    toxicLimits: 'Gränsvärden',
    totalLimits: 'Totalgränser',
    sumRuleLimits: 'Summa',
    mgKgTS: '(mg/kg TS)',
    specifiedContent: 'Angiven halt',
    limit: 'Gräns',
    hazardousType: 'Farlig typ',
    hazardousWasteLimitTitle: 'FA gräns',
    hazardousWasteLimit: 'FA gräns (mg/kg TS)',
    measuredMassContent: 'Uppmätt halt i massa (mg/kg TS)',
    specialLimit: 'Särskild gräns',
    minAmount: 'Min Mängd',
    maxAmount: 'Max Mängd',
    yes: 'Ja',
    notifyUserWithEmail: 'MEDDELA ANVÄNDARE MED E-POST',
    no: 'Nej',
    pricePerUnit: 'Pris per enhet',
    default: '[DEFAULT]',
    unknown: '[Okänt]',
    doYouReallyWantDelete: 'Vill du verkligen radera?',
    oneTripCost: 'Pris för en transport, med {number} ton',
    oneTripCostShort: 'Pris för en transport',
    tripCost: 'Pris/Km',
    useTrips: 'Antal transporter',
    internalLoadFeePerTon: 'Mottagningsavgift/ton (intern)',
    loadFee: 'Mottagningsavgift',
    loadFeePerTon: 'Mottagningsavgift/ton',
    total: 'Total',
    fastest: 'Snabbast',
    normal: 'Normal',
    enterLocationAddress: 'Ange platsadress...',
    pricePerTon: 'Pris/ton',
    account: 'Konto',
    add: 'Lägg till',
    manage: 'Hantera',
    created: 'Skapad',
    lastUpdated: 'Senast uppdaterad',
    addSubstance: 'Lägg till ämne',
    sysAdmin: 'Administratör',
    pplAdmin: 'PPL Administratör',
    amount: 'Mängd',
    amountFieldHint: 'Mängd i ton eller kubikmeter',
    overview: 'Översikt',
    editSite: 'Redigera mottagningsplats',
    materials: 'Material',
    updateAtErrorMessage:
        'Informationen kan inte sparas eftersom sidan är låst för redigering. Vänligen vänta några sekunder, läs om eller öppna sidan på nytt.',
    apply: 'Använd',
    availableLandfills: 'Tillgängliga Mottagare',
    backToMap: 'Visa karta',
    cancel: 'Avbryt',
    capacity: 'Kapacitet',
    capacityList: 'Kapaciteter',
    changeEmail: 'Byt e-post',
    changeEmailIfIsIncorrect:
        'Om e-postadressen ovan är fel kan du ändra e-postmeddelandet genom att klicka på knappen nedan.',
    emailWasSentTo: 'Ett e-postmeddelande skickades till:',
    checkYourInbox: 'Kontrollera din inkorg',
    verifyEmail: 'Verifiera e-post',
    changePassword: 'Ändra lösenord',
    passwordHasBeenChanged: 'Lösenordet har ändrats',
    change: 'Förändra',
    close: 'Stäng',
    comment: 'Kommentar',
    contactInfo: {
        pinpointer: {
            email: 'kundtjanst@pinpointer.se',
            phone: '0771-67 10 10',
        },
    },
    create: 'Skapa',
    createAccount: 'Skapa konto',
    enterYourDetail: 'Ange dina uppgifter',
    co2: 'co2',
    createNewLandfill: 'Skapa ny mottagningsplats',
    createNewLandOnMap: 'Skapa ny mottagning',
    createNewProject: 'Skapa nytt projekt',
    createNewSubstance: 'Lägg till nytt ämne',
    createNewCompany: 'Skapa ny kund',
    createSubType: 'Lägg till',
    createNewMass: 'Skapa ny massa',
    cubicMeters: 'm3',
    currentAmount: 'Aktuell mängd',
    currentPosition: 'Aktuell position',
    customer: 'Kund',
    company: 'Företag',
    contactsFieldHint: 'Kontaktpersoner kan läsa all information, men inte lägga till eller ändra något',
    delete: 'Radera',
    shortest: 'Kortast',
    dumpLoad: 'Massa',
    dumpLoads: 'Massor',
    dumpTypeLabelNew: 'Skapa ny masstyp',
    dumpTypeName: 'Namn på massa',
    dumpTypes: 'Typer av massor',
    duration: 'Tid',
    edit: 'Ändra',
    editDumpType: 'Ändra typ av massa',
    editLandfill: 'Ändra mottagningsplats',
    editLandfillStatus: 'Ändra mottagarens status',
    editProject: 'Ändra projekt',
    editSubstance: 'Ändra ämne',
    editUser: 'Ändra användare',
    editDriver: 'Redigera transportör',
    email: 'E-Post',
    emailIsRequired: 'E-post krävs',
    busAdmin: 'Vanlig användare',
    enterCustomerName: 'Ange kundens namn...',
    enterNewUserEmail: 'Ange ny e-post för användare...',
    enterNewUserEmailToBlock: 'Lägg till e-post till Svartlistan',
    enterProjectComment: 'Ange kommentar till projekt...',
    enterLandfillComment: 'Ange kommentar till anläggning...',
    error: 'Ett fel uppstod...',
    errorRetry: 'Ett fel uppstod. Vänligen försök igen senare. Kontakta supporten om problemet kvarstår.',
    firstName: 'Förnamn',
    surName: 'Efternamn',
    gateFee: 'Entré-/vågavgift',
    minMargin: 'Minsta marginal (kr)',
    icon: 'Ikon',
    blacklist: 'Svartlista',
    invalidCredentials: 'Ogiltiga uppgifter',
    invalidEmail: 'E-post ogiltlig',
    isActive: 'Aktiv',
    labelNew: 'Skapa ny typ av mottagningsplats',
    landfill: 'mottagningsplats',
    landfillInfo: 'Grunduppgifter',
    landfillName: 'Namn på mottagningsplats',
    landfillNamePlaceholder: 'Namn på mottagningsplats...',
    landfills: 'mottagningsplatser',
    landfillStatus: 'Status för mottagningsplats',
    landfillStatuses: 'Status för mottagningsplats',
    landfillStatusTitle: 'Namn på status för mottagningsplatsen',
    landfillNoToxicResults: 'Gränsvärden saknas.',
    lastName: 'Efternamn',
    loading: 'Laddar...',
    loadingMap: 'Laddar kartan...',
    location: 'Plats',
    longitude: 'Longitud',
    latitude: 'Latitud',
    dates: 'Datum',
    datesFieldHint: 'Start- och slutdatum för denna mässan',
    signIn: 'Logga in',
    login: 'Logga in',
    loginIsRequired: 'Inloggning krävs',
    logout: 'Logga ut',
    manager: 'Ansvarig',
    mapSettings: 'Kartinställningar',
    minPasswordLength: 'Lösenordet måste innehålla minst sex (6) tecken!',
    name: 'Namn',
    stackability: 'Stapelbar',
    over50cm: 'Över 50cm',
    withTOC: 'TOC (%)',
    TOCValue: 'TOC-värde',
    withInvasiveSpecies: 'Kan ta emot invasiva arter',
    invasiveSpecies: 'Description of invasive species',
    orderTransportation: 'Beställ transport',
    orderTransportationFieldHint: 'Vill du köpa transport via Pinpointer',
    discardOrder: 'Makulera Order',
    order: 'Order',
    noCompany: 'Inget företag',
    maxTrips: 'Max antal transporter',
    retry: 'Försök igen',
    status: 'Status',
    registered: 'Registrerad',
    emailConfirmed: 'e-post bekräftad',
    approved: 'Godkänd',
    nameIsRequired: 'Namn krävs',
    surNameIsRequired: 'Efternamn krävs',
    oldPassword: 'Gammalt lösenord',
    newPassword: 'Nytt lösenord',
    repeatPassword: 'Repetera lösenord',
    currentPasswordIsRequired: 'Gammalt lösenord krävs',
    samePasswordAsBefore: 'Lösenorden är desamma',
    saved: 'Sparad',
    noCapacitySpecified: 'Ingen kapcitet definierad',
    organizationNumber: 'Organisationsnummer',
    organizationNumberIsRequired: 'Organisationsnummer saknas',
    organizationNumberMustBeAnInteger: 'Organisationsnummret skall endast bestå av siffror',
    organizationName: 'Företagsnamn',
    organizationNameIsRequired: 'Företagsnamn saknas',
    organizationNumberMustBeTenDigit: 'Organisationsnummer måste vara 10 siffror',
    GLNMustBeInCorrectFormat: 'GLN måste vara 13-siffrigt',
    open: 'Öppna',
    openedAt: 'Öppettider',
    openHours: 'Öppettider',
    openLandfill: 'Gå till mottagningsplats',
    openProject: 'Gå till projektet',
    password: 'Lösenord',
    passwordIsRequired: 'Lösenord krävs',
    confirmPassword: 'Repetera lösenord',
    confirmPasswordIsRequired: 'Repetera lösenordet',
    confirmTermsIsRequired: 'Godkännande av villkor krävs',
    passwordsDoNotMatch: 'Lösenorden måste stämma överrens',
    pickPositionOnTheMap: 'Välj plats på kartan...',
    project: 'Projekt',
    projectDumpLoads: 'Massor',
    projects: 'Projekt',
    projectToxicAnalysys: 'Gränsvärden',
    remove: 'Ta bort',
    report: 'Rapport',
    resetPassword: 'Återställ lösenord',
    approveUser: 'Godkänn användare',
    role: 'Roll',
    roleIsOnlyOne: 'Du kan endast välja en roll',
    routeDetails: 'Ruttinformation',
    saving: 'Sparar!',
    save: 'Spara',
    saveFormAlert: 'Informationen har ändrats och måste sparas. Vill du spara?',
    saveAndClose: 'Spara och stäng',
    saveRequestAndClose: 'Begär offert och stäng',
    saveAsDraft: 'Spara som utkast',
    markAsResolved: 'Markera som avslutad',
    confirm: 'Bekräfta',
    searchDumpType: 'Sök typ av massa...',
    searchLandfill: 'Sök mottagningsplats...',
    searchProject: 'Sök projekt...',
    searchSubstance: 'Sök ämne...',
    searchUser: 'Sök användare...',
    searchDriver: 'Sök förare...',
    searchCompany: 'Sök företag...',
    substanceName: 'Ämnets namn',
    substance: 'Ämne',
    substances: 'Ämnen',
    title: 'Konto',
    tonns: 'Ton',
    tonnsShort: 't.',
    toxic: 'Ämnen',
    toxicAnalysys: 'Analys av gränsvärden',
    toxicResult: 'Resultat av gränsvärdesberäkning',
    toxicRule: 'Regel för gränsvärden',
    toxicRule_Alvenas: 'Älvenäs',
    toxicRule_GreenList: 'Gröna listan',
    toxicRule_Inert: 'Laktest',
    toxicRule_SumRule: 'Summeringsregeln',
    toxicRule_TotalContent: 'Totalhalt',
    toxicRuleName: 'Benämning för regel på gränsvärden',
    toxicRules: 'Regler för gränsvärden',
    configSaved: 'Konfigurationen sparad',
    tripCount: 'Antal transporter',
    trips: 'Antal turer',
    type: 'Typ',
    unitOfMeasure: 'Enhet',
    update: 'Uppdatera',
    updatedAt: 'Senast ändrad',
    users: 'Användare',
    wasteTypes: 'Avfallstyper',
    zero: 0,
    groupBy: 'Gruppera på',
    max: 'MAX',
    sum: 'SUM',
    invalidLat: 'Latitude är ogiltig. Det borde vara mellan -90 och 90. Och inte 0.',
    invalidLng: 'Längden är ogiltig. Det borde vara mellan -180 och 180. Och inte 0.',
    locationNotFound: 'Plats ej hittad!',
    invalidValues: 'Ogiltiga värden ändras:',
    stackable: 'Stapelbar',
    loginCarouselHeader1: 'Din arbetsprocess förenklad',
    loginCarouselText1:
        'Alla dokumentation är digital och lagras i systemet, vilket gör processen enklare och säkrare samt att det når rätt händer.',
    loginCarouselHeader2: 'Vårt miljölöfte',
    loginCarouselText2:
        'Målet med Pinpointer är att minimera koldioxidutsläpp genom att optimera transporterna och se till att alltid välja bäst mottagningsplats.',
    loginCarouselHeader3: 'Överblick av pågående transporter',
    loginCarouselText3:
        'Med Pinpointer ges möjlighet att se pågående transporter och statistik vilket bidrar till att optimera och organisera Ditt flöde av material.',
    fortnox: {
        title: 'Fortnox',
        deliveryAddress: 'Leveransadress',
        orderNumber: 'Ert ordernr',
        partNumber: 'Artikelnummer',
        contamination: 'Beskrivning',
        amount: 'Antal',
        deliveryDate: 'Leveransdatum',
        lastSyncWithError: 'Den senaste synkroniseringen misslyckades',
        nothingToSync: 'Inget att synkronisera',
        syncStillRunning: 'Synkronisering pågår. Var vänlig vänta tills synkroniseringen avslutas.',
        syncInProgress: 'Synkronisering pågår...',
        ordersToSync: 'Order att synkronisera: {count}.',
        getSyncTimeInfo: 'Det kommer att ta ca {duration} och kommer att slutföras efter {remaining}',
        syncPageUpdateInfo: 'Sidan uppdateras automatiskt efter avslutad process.',
    },
    selectDateRange: 'Välj datumintervall',
    projectNumber: 'Projektnummer',
    findOrder: 'Sök order...',
    sync: 'Synkronisera',
    titleEdit: 'Redigera order',
    orderNumber: 'Ordernummer',
    orderStatus: 'Orderstatus',
    deliveryAddress: 'Mottagningsplats',
    plannedStartDate: 'Planerat Startdatum',
    deliveredVolume: 'Levererad volym',
    salesResponsible: 'Ansvarig säljare',
    volume: 'Volym',
    partNumber: 'Artikelnummer',
    startDateFrom: 'Startdatum',
    startDateTo: 'Slutdatum',
    deliveredVolumeFrom: 'Levererad volym från',
    deliveredVolumeTo: 'Levererad volym till',
    history: 'Historik',
    invoices: 'Fakturor',
    invoicesPage: {
        title: 'Fakturaunderlag',
        selectDateRange: 'Välj datumintervall',
        alert: 'Vänligen fyll i formuläret',
    },
    buttons: {
        sync: 'Synkronisera',
        syncStatus: 'Synkroniseringsstatus',
    },
    pplOrder: {
        alert: 'Kund krävs',
        selectDateRange: 'Välj datumintervall',
        projectNumber: 'Projektnummer',
        findOrder: 'Sök order...',
        sync: 'Synkronisera',
        syncStatus: 'Synkronisering status',
        syncStop: 'Stoppa synkroniseringen',
        syncStopped: 'Synkroniseringen har stoppats',
        syncOneOrder: 'Synkronisera el order',
        enterDocumentNumber: 'Vänligen ange Fortnox ordernummer (dokument). Inte "Your order number"!',
        title: 'Order',
        titleEdit: 'Redigera order',
        orderNumber: 'Ordernummer',
        documentNumber: 'Dokumentnummer',
        orderStatus: 'Orderstatus',
        customer: 'Kund',
        deliveryAddress: 'Mottagningsplats',
        plannedStartDate: 'Planerat Startdatum',
        deliveredVolume: 'Levererad volym',
        salesResponsible: 'Ansvarig säljare',
        volume: 'Volym',
        partNumber: 'Artikelnummer',
        startDateFrom: 'Startdatum',
        startDateTo: 'Slutdatum',
        deliveredVolumeFrom: 'Levererad volym från',
        deliveredVolumeTo: 'Levererad volym till',
        history: 'Historik',
        showDateRange: 'Visa datumintervall',
        deliveryName: 'Mottagarnamn',
        create: 'Skapa',
        createOrder: 'Skapa ny order, ange avfallsdeklarations-nummer',
    },
    pplDeliveryLine: {
        history: 'Historik',
        search: 'Sök leveransrad...',
        title: 'Leveransrad',
        orderNumber: 'Ordernummer',
        deliveryLine: 'Leveransrad',
        partNumber: 'Artikelnummer',
        weight: 'Vikt',
        receiptPhoto: 'Vågkvitto',
        customerInvoiceNumber: 'Kundfaktura',
        transportationInvoiceNumber: 'Transportörfaktura',
        customerInvoiceNo: 'Fakturanr. Kund',
        transportationInvoiceNo: 'Fakturanr. Mottagning',
        landfillInvoiceNo: 'Fakturanr. Mottagare',
        date: 'Datum',
        truckRegistrationNumber: 'Reg.nummer',
        transportCompany: 'Transportör',
    },
    MassCard: {
        transported: 'Levererat',
        ordered: 'Beställt',
        received: 'Mottaget',
        remaining: 'Återstående',
    },
    pplCompany: {
        mergedTo: 'Sammanslogs:',
        title: 'Kunder',
        customerNumber: 'Kundnummer',
        organizationNumber: 'Organisationsnummer',
        companyName: 'Företagsnamn',
        phone: 'Telefonnummer',
        phone2: 'Telefonnummer 2',
        email: 'E-post',
        web: 'Webb',
        contactPerson: 'Kontaktperson',
        contactPerson2: 'Kontaktperson 2',
        invoiceAddress: 'Fakturaadress',
        invoiceAddress2: 'Fakturaadress 2',
        zipCode: 'Postnummer',
        city: 'Stad',
        country: 'Land',
        comment: 'Kommentar',
        active: 'Aktiva',
        drivers: 'Chaufförer',
        addDriver: 'Lägg till en Chaufför',
        sync: 'Synkronisera',
        syncStatus: 'Synkronisering status',
        syncStop: 'Stoppa synkroniseringen',
        syncStopped: 'Synkroniseringen har stoppats',
        alert: 'Namn krävs',
        mergeTo: 'Sammanfoga',
        selectCompanyToMerge: 'Välj en annan kund',
        toSelectedCompany: 'till vald kund',
        toCurrentCompany: 'till nuvarande kund',
        apiKey: 'API-nyckel',
        generateAPIKey: 'Generera API-nyckel',
        generationSuccess: 'API-nyckel genererad',
    },
    pplSynchronize: {
        completed: 'Färdig!',
        error: 'FEL: ',
        inprogress: 'Pågår: ',
    },
    driver: {
        title: 'Transportörer',
        name: 'Namn',
        status: 'Status',
        registered: 'Registreringsdatum',
        email: 'e-post',
        phoneNumber: 'Telefonnummer',
        companies: 'Kunder',
        filter: {
            ACTIVE: 'Aktiva',
            INACTIVE: 'Inaktiv',
            ARCHIVED: 'Arkiverad',
        },
    },
    errorCodes: {
        INTERNAL_ERROR: 'Internt serverfel',
        REQUEST_TIMEOUT:
            'Servern mottog inte ett fullständigt förfrågningsmeddelande inom den tid det var berett att vänta.',
        INVALID_ID: 'Ogiltigt ID',
        PROJECT_NOT_FOUND: 'Projekt hittades inte!',
        LANDFILL_NOT_FOUND: 'Depon hittades inte!',
        LANDFILL_EVENT_NOT_FOUND: 'Mottagarens leveranshändelse hittades inte!',
        LANDFILL_EVENT_ALREADY_ATTACHED: 'Mottagarens leveranshändelse redan bifogad!',
        DRIVER_NOT_FOUND: 'Transportör hittades inte!',
        DRIVER_EMPTY_PASSWORD: 'Tomt lösenord',
        DRIVER_INVALID_OLD_PASSWORD: 'Ogiltigt gammalt lösenord',
        DRIVER_PASSWORD_NOT_EQUAL: 'Lösenord är inte lika',
        DRIVER_EMAIL_EXISTS: 'Drivrutin med samma e-postadress finns redan',
        DRIVER_PHONE_EXISTS: 'Drivrutin med samma telefon finns redan',
        DRIVER_IS_NOT_ACTIVE: 'Drivrutinen är inte aktiv',
        INVALID_VERIFICATION_CODE: 'Ogiltig kontrollkod',
        NETWORK_ERROR: 'Ett nätverksfel inträffade!',
        ACCESS_VIOLATION: 'Åtkomst nekas!',
        NOT_NULL_EXPECTED: 'Ett tomt värde förväntades inte!',
        FORTNOX_ENTITY_NOT_FOUND: 'Fortnox-enheten hittades inte! Denna enhet kan inte synkroniseras alls',
        FORTNOX_SYNC_TIMEOUT: 'Tyvärr, fortnox synkronisering timeout. Kör den igen',
        FORTNOX_ANOTHER_SYNC_IN_PROGRESS: 'En annan synkronisering pågår. Kör den igen senare',
        FORTNOX_NETWORK_ERROR:
            'Ett nätverksfel uppstod under kommunikation med Fortnox. Var god försök igen. Kontakta teknisk support om felet inte försvann',
        FORTNOX_SYNC_CANCELED: 'Fortnox-synkronisering avbröts av användaren',
        INVALID_INPUT: 'Felaktig inmatning!',
        END_DATE_IS_BEFORE_START_DATE: 'Slutdatumet är före startdagen!',
        LANDFILL_INVALID_RECEIVER_USER: 'Mottagningsanläggningen kan inte tilldelas vald användare.',
        SUBAREA_PRICE_NOT_SPECIFIED: 'Materialbaspris bör anges!',
        SUBAREA_USED_IN_MATCHING: 'Material används vid matchning!',
        INVALID_DATE: 'Ogiltigt datum',
        VALUE_ALREADY_CHANGED:
            'Informationen kan inte sparas eftersom sidan är låst för redigering. Vänligen vänta några sekunder eller öppna sidan på nytt',
        ORDER_NOT_FOUND: 'Beställningen hittades inte!',
        ORDER_ALREADY_ATTACHED: 'Ej tillåtet. Beställningen är redan knuten till en beställning!',
        ORDER_RECEIPTS_DIRECTORY_EXISTS: 'Ej tillåtet. Det finns redan en katalog på server!',
        ORDER_RECEIPTS_DIRECTORY_RENAME_ERROR: 'Fel. Kan inte byta namn på orderkatalog',
        ORDER_RECEIPTS_FILE_RENAME_ERROR: 'Fel. Det går inte att byta namn på kvitteringsfilen',
        PROJECT_NAME_INVALID: 'Ogiltigt projektnamn!',
        PROJECT_HAS_CUSTOMER: 'Ej tillåtet. Projektet har en användare från ett annat företag!',
        PROJECT_HAS_ORDER_WITH_OTHER_CUSTOMER: 'Ej tillåtet. Project har redan beställt med en annan kund!',
        PROJECT_ACTIVE_LOCATION_CANNOT_BE_CHANGED: 'Projektet är aktivt, platsen kan inte ändras!',
        PROJECT_IS_NOT_AVAILABLE: 'Projektet är inte tillgängligt',
        DUMP_LOAD_NOT_FOUND: 'Massa hittades inte!',
        DUMP_LOAD_STATUS_UNKNOWN: 'Statusen är okänd.',
        DUMP_LOAD_INVALID_TARGET_STATUS_OR_NO_PERMISSIONS: 'Felaktig status eller inga behörigheter.',
        DUMP_LOAD_NO_PERMISSIONS: 'Behörighet saknas!',
        DUMP_LOAD_EDIT_FORBIDDEN_IN_CURRENT_STATUS: 'Ej tillåtet att redigera i nuvarande status!',
        DUMP_LOAD_FIELDS_HAVE_INVALID_VALUES: 'Nästa fält måste fyllas i',
        DUMP_LOAD_NOT_SPECIFIED: 'Massa hittades inte!',
        DUMP_LOAD_NOT_SIGNED: 'Det är går inte att uppdatera status innan dokumentet signeras!',
        DUMP_LOAD_NEED_TO_SEND: 'Det går inte att uppdatera status innan dokumentet skickats för signering!',
        DUMP_LOAD_CUSTOMER_NEED_FOR_SIGN: 'Det är inte tillåtet att skicka för signering utan en kund!',
        DUMP_LOAD_COMMENT_REQUIRED: 'Massbeskrivning får inte vara tom!',
        DUMP_LOAD_HAS_DESPATCH_ADVICE: 'Mass har redan skickat despatch advice!',
        CANNOT_SET_DATE_IN_PAST: 'Ej tillåtet att sätta ett datum tillbaka i tiden!',
        PROJECT_EDIT_FORBIDDEN_IN_CURRENT_STATUS: 'Ej tillåtet att redigera projektet i nuvarande status!',
        LINK_EXPIRED: 'Länken har löpt ut',
        USER_EXISTS: 'Användaren finns redan!',
        USER_NOT_FOUND: 'Användaren hittades inte!',
        USER_REQUIRED_CUSTOMER: 'Användares företagsfält får inte vara tomt!',
        USER_EMPTY_OLD_PASSWORD: 'Gammalt lösenord ej angivet!',
        USER_INVALID_OLD_PASSWORD: 'Gammalt lösenord ogiltligt!',
        USER_PASSWORD_NOT_EQUAL: 'Lösenorden är inte lika!',
        USER_PASSWORD_SIMILAR_TO_CURRENT: 'Nytt lösenord lika med det nuvarande!',
        USER_HAS_LINKED_PROJECTS:
            'Användaren har länkade projekt! Ta bort alla projekt innan du ändrar användarrollen!',
        USER_NAME_CANNOT_BE_EMPTY: 'Användarnamnet kan inte vara tomt!',
        USER_SURNAME_CANNOT_BE_EMPTY: 'Användarens efternamn kan inte vara tom!',
        USER_ALREADY_INVITED: 'Användare redan inbjuden!',
        USER_ALREADY_JOINED_PROJECT: 'Användaren har redan gått med i projektet!',
        INVALID_ROLE_FOR_EMAIL: 'Användare med angiven e-postadress kunde inte läggas till för den valda rollen!',
        PROJECT_NAME_CANNOT_BE_EMPTY: 'Projektnamnet kan inte vara tomt!',
        PROJECT_INVOICE_REFERENCE_CANNOT_BE_EMPTY: 'Projektfakturareferens får inte vara tom!',
        LANDFILL_NAME_CANNOT_BE_EMPTY: 'Namn kan inte vara tomt!',
        LANDFILL_STATUS_NOT_FOUND: 'Status hittades inte!',
        LANDFILL_STATUS_NAME_CANNOT_BE_EMPTY: 'Namn kan inte vara tomt!',
        LANDFILL_STATUS_EXISTS: 'Statusen finns redan!',
        LANDFILL_TYPE_CANNOT_BE_EMPTY: 'Typ får inte vara tom!',
        LANDFILL_CUSTOMER_REQUIRED: 'Mottagarkund krävs',
        TOC_VALUE_REQUIRED: 'TOC-värde krävs',
        TOC_VALUE_INCORRECT: 'TOC-värdet bör vara mellan $1 och $2',
        INVASIVE_SPECIES_REQUIRED: 'Invasiva arter krävs',
        LANDFILL_IS_NOT_AVAILABLE: 'Mottagare är inte tillgänglig',
        EMPTY_OWNER: 'Ägare saknas!',
        TOXIC_LIMITS_INVALID_VALUE: 'Gränsvärdet har ogiltigt värde!',
        DUMP_LOAD_STATUS_NOT_FOUND: 'Status för aktuellt läge hittades inte!',
        DUMP_TYPE_NOT_FOUND: 'Dumptyp hittades inte!',
        DUMP_TYPE_EXISTS: 'En typ med samma namn finns redan!',
        DUMP_TYPE_NAME_CANNOT_BE_EMPTY: 'Typens namn kan inte vara tomt!',
        DUMP_TYPE_VOLUME_CANNOT_BE_EMPTY: 'Typens volym kan inte vara tomt!',
        DUMP_TYPE_TONS_PER_M3_MUST_BE_POSITIVE: 'Typens ton per m3 måste vara positiv!',
        DUMP_LOAD_CAN_NOT_BE_CREATED_FOR_DELETED_CUSTOMER: 'Dumplast kan inte skapas för projekt för borttagen kund',
        EMAIL_EXISTS: 'Denna e-postadressen existerar redan',
        EMAIL_BLOCKED: 'Den här e-postadressen är blockerad',
        PERMISSION_ERROR: 'Behörighet saknas!',
        PLEASE_SELECT_ADDRESS_ON_MAP: 'Välj adress på kartan!',
        DELIVERY_LINE_NOT_FOUND: 'Leveransen hittades inte!',
        COMPANY_NOT_FOUND: 'Kund hittades inte',
        COMPANY_DUPLICATE_ORGANIZATION_NUMBER: 'Hittade ett företag med samma organisationsnummer',
        COMPANY_DUPLICATE_GLN: 'Hittade ett företag med samma GLN',
        COMPANY_HAS_CONNECTED_ENTITIES: 'Företaget kan inte tas bort. Den har kopplade aktiva enheter',
        SUBSTANCE_IS_USED: 'Ämnet används och kan inte tas bort',
        SUBSTANCE_CONTAMINATION_TYPE_IS_USED: 'Ämneskotamineringstyp används!',
        SUBSTANCE_NOT_FOUND: 'Ämnet hittades inte',
        EMPTY_COMPANY: 'Tom kund!',
        NOT_IMPLEMENTED: 'Ej implementerad!',
        FILE_NOT_FOUND: 'Filen hittades inte!',
        INVALID_FILE_NAME: 'Filnamnet är ogiltigt!',
        DEVIATION_NO_PERMISSIONS: 'Behörighet saknas',
        DEVIATION_EDIT_WRONG_STATUS: 'Fel status för avvikelse',
        DEVIATION_NOT_FOUND: 'Avvikelse hittades inte!',
        DEVIATION_INVALID_FIELDS: 'Obligatoriska fält kan inte vara tomma!',
        CONVERSION_TO_PDF_ERROR: 'Fel vid konvertering till PDF!',
        PROJECT_END_DATE_BEFORE_START_DATE: 'Projektets slutdatum kan inte vara före projektets startdatum!',
        PROJECT_NO_DATE_CHANGE_ONGOING_MASS: 'Projektdatum kan inte ändras på grund av massa pågående status!',
        PROJECT_START_DATE_AFTER_MASS_START_DATE: 'Startdatum för projektet kan inte vara efter massstartdatum!',
        PROJECT_END_DATE_BEFORE_MASS_END_DATE: 'Projektets slutdatum kan inte vara före slutdatumet för massan!',
        PROJECT_CUSTOMER_REQUIRED: 'Projektets kund krävs',
        MASS_START_DATE_BEFORE_PROJECT_START_DATE: 'Massstartdatum kan inte vara före projektets startdatum!',
        MASS_START_DATE_BEFORE_TODAY: 'Massstartdatum kan inte vara före idag!',
        MASS_END_DATE_BEFORE_PROJECT_START_DATE: 'Slutdatum för massa kan inte vara före projektets startdatum!',
        MASS_END_DATE_BEFORE_MASS_START_DATE: 'Slutdatum för massa kan inte vara före början av massan!',
        MASS_END_DATE_BEFORE_TODAY: 'Slutdatum för massa kan inte vara före idag!',
        PROJECT_END_DATE_MUST_BE_UPDATED: 'Projektets slutdatum måste uppdateras innan slutdatumet ställs in!',
        PROJECT_LOCATION_CANNOT_BE_EMPTY: 'Projektplatsen kunde inte vara tom',
        PROJECT_LOCATION_ON_WATER: 'Projektplats kan bara vara på land',
        LANDFILL_LOCATION_CANNOT_BE_EMPTY: 'Mottagningsplatsen får inte vara tom',
        LANDFILL_LOCATION_ON_WATER: 'Deponeringsplats kan bara vara på land',
        LOCATION_UNREACHABLE: 'Platsen går inte att nå',
        LOCATION_NOT_GEOCODED: 'Slutför inmatningen till geokodsplatsen',
        OPEN_FROM_AFTER_OPEN_TO: 'Öppettiden bör vara före stängningstid',
        NO_OPEN_WORK_DAY: 'Bör vara minst en öppen arbetsdag',
        FILE_EXISTS: 'En fil med samma namn finns redan!',
        FILE_TOO_LARGE: 'Filstorleken är för stor',
        API_KEY_NOT_SPECIFIED: 'API-nyckel har inte angetts',
        API_KEY_INVALID: 'API-nyckel ogiltig',
        DELIVERY_LINE_ORDER_CANNOT_BE_EMPTY: 'Beställning bör anges',
        DELIVERY_LINE_WEIGHT_CANNOT_BE_EMPTY: 'Vikt bör anges',
        DELIVERY_LINE_DATE_CANNOT_BE_EMPTY: 'Datum bör anges',
        DELIVERY_LINE_TRANSPORT_COMPANY_CANNOT_BE_EMPTY: 'Transportföretag bör anges',
        DELIVERY_LINE_TRUCK_NUMBER_CANNOT_BE_EMPTY: 'Lastbilens registreringsnummer ska anges',
        DELIVERY_LINE_VEHICLE_TYPE_CANNOT_BE_EMPTY: 'Fordonstyp ska anges',
        DELIVERY_LINE_FUEL_CANNOT_BE_EMPTY: 'Bränsle bör anges',
        DELIVERY_LINE_ENGINE_CANNOT_BE_EMPTY: 'Motor bör specificeras',
        DELIVERY_LINE_RECEIPT_CANNOT_BE_EMPTY: 'Kvitto ska bifogas',
        DELIVERY_LINE_DUMP_LOAD_IS_DONE: 'Massan för denna beställning har statusen Avslutad',
        INVALID_LOCATION: 'Ogiltig plats',
        TOO_MANY_REQUESTS: 'Daglig e-postgräns nådd',
        PHONE_NUMBER_EXISTS: 'Telefonnumret används redan i applikationen',
        PHONE_NUMBER_HAVE_TO_BE_LOCAL_OR_INTERNATIONAL: 'Telefonnumret måste börja med 0 eller +46',
        PHONE_NUMBER_TOO_SHORT: 'Telefonnumret är för kort',
        PHONE_NUMBER_TOO_LONG: 'Telefonnumret är för långt',
        INVALID_PHONE_NUMBER: 'Ogiltigt telefonnummer',
        NOT_AUTHORIZED: 'Användaren är inte auktoriserad',
        USER_BLOCKED: 'Användare blockerad',
        USER_NOT_CONFIRMED: 'Användarens e-postadress inte bekräftad',
        USER_NOT_VERIFIED: 'Användare inte godkänd',
        INVALID_ORGANIZATION_NUMBER: 'Ogiltigt organisationsnummer',
        INVALID_GLN: 'Ogiltigt GLN',
        ORDER_NUMBER_REQUIRED: 'Ordernummer kan inte vara tomt',
        ORDER_PLANNED_START_DATE_REQUIRED: 'Beställningens planerade startdatum kan inte vara tomt',
        ORDER_DUPLICATE_ORDER_NUMBER: 'Beställning med samma ordernummer hittades',
        VALUE_ALREADY_EXISTS: 'Samma värde finns redan',
        GOOGLE_API_ERROR: 'Geokodtjänstfel',
        GOOGLE_API_ZERO_RESULTS: 'Geocode misslyckades, inga resultat hittades',
        LIMIT_OF_FILES_FOR_DOWNLOAD_IS_OUT_OF_RANGE:
            'Gränsen för filer som är tillgängliga för nedladdning är utanför intervallet. Välj färre filer att ladda ner',
        UNKNOWN_GQL_ERROR: 'Okänt GQL -fel',
        TRUCK_NOT_FOUND: 'Lastbil hittades inte',
        ANOTHER_DRIVER_DELIVERY_LINE_IN_PROGRESS: 'Föraren har redan leveranslinje på gång',
        ALREADY_TRUCK_DELIVERY_LINE_IN_PROGRESS: 'Denna lastbil har redan leveranslinje på gång',
        WRONG_VERSION: `Den version av programmet du använder är inaktuell. 
Ladda om sidan för att fortsätta.
Windows: ctrl + F5
MacOS: cmd + shift + R
`,
        BEAST_MATERIAL_EXISTS: 'BEAst-material finns redan',
        BEAST_MATERIAL_ID_CANNOT_BE_EMPTY: 'Id kan inte vara tomt!',
        BEAST_MATERIAL_NAME_CANNOT_BE_EMPTY: 'Namn kan inte vara tomt!',
        DELIVERY_LINE_HAS_PEPPOL_RESPONSE:
            'Leveransen kan inte uppdateras eftersom den har accepterat Despatch Advice Response',
        TIME_SPENT_HAS_PEPPOL_RESPONSE:
            'Tidsåtgång kan inte uppdateras eftersom den har accepterat svar på sändningsråd',
        INVALID_TEST_PEPPOL_PARTIES: 'Felaktiga inställningar för test-PEPPOL-fester',
        VEHICLE_TYPE_IS_USED: 'Kan inte ta bort fordonstyp, den används i användarnas fordon',
        VEHICLE_TYPE_NOT_FOUND: 'Fordonstyp hittades inte',
        NO_ACTIVE_WHATSAPP_PHONE: 'Ingen aktiv telefon för whatsapp api',
        INCORRECT_CHAT_GPT_ASSISTANT_ID: 'Felaktigt ID för chatt-GPT-assistent',
        OPEN_AI_ERROR: 'ChatGPT API-fel uppstod',
    },
    signStatuses: {
        [SignDocumentStatuses.CREATED]: 'Signeringsförfrågan har skapats. Väntar på att processen börjar',
        [SignDocumentStatuses.SIGN_PROGRESS]: 'Väntar på parternas undertecknande',
        [SignDocumentStatuses.SIGNED]: 'Dokument undertecknat',
        [SignDocumentStatuses.ERROR]: 'Ett oväntat fel uppstod när dokumentet undertecknades',
        [SignDocumentStatuses.TIME_OUTED]: 'Tiden för att underteckna dokumentet har gått ut',
        [SignDocumentStatuses.CANCELED]: 'Dokumentet avbröts av Pinpointer',
        [SignDocumentStatuses.REJECTED]: 'En av parterna avböjde att underteckna dokumentet',
    },
    signActions: {
        checkEmail: 'Signeringsförfrågan har skapats. Kolla din mail.',
    },
    DumploadStatusQuestMarkAsDone:
        'Är du säker på att du vill avlsuta denna beställningen? Inga nya transporter registreras på ordern efter avslut.',
    DumploadActions: {
        REOPEN: 'Återöppna',
        REQUEST: 'Begär Offert',
        DISCARD: 'Ta bort',
        REJECT: 'Avvisa Offert',
        CONFIRM: 'Godkänn Offert',
        BUY: 'KÖP',
        START_DELIVERY: 'Starta leveranser',
        DONE: 'Avsluta',
        waitSender: 'Väntar på en uppdatering av beställaren',
        waitPinpointer: 'Väntar på en uppdatering av Pinpointer',
        nextStatusDisabled: 'Ej möjligt att gå vidare med ordern',
        requestQuoteInfo:
            'Genom att begära in en offert binder Ni er inte att köpa något och det är helt avgiftsfritt.',
    },
    dateFormat: 'yyyy-MM-dd',
    at: 'kl.',
    DumploadStatuses: {
        DISCARDED: 'Borttagen',
        NOT_ORDERED: 'Order avböjd',
        DRAFT: 'Utkast',
        REQUESTED: 'Offert begärd',
        CONFIRMED: 'Offert bekräftad',
        ORDERED: 'Offert beställd',
        IN_PROGRESS: 'Pågående',
        DONE: 'Avslutad',
    },
    DumploadStatusesSochi: {
        DISCARDED: 'Borttagen',
        NOT_ORDERED: 'Order avböjd',
        DRAFT: 'Utkast',
        REQUESTED: 'Offert begärd',
        CONFIRMED: 'Offert mottagen',
        ORDERED: 'Order beställd',
        IN_PROGRESS: 'Order pågående',
        DONE: 'Order avslutad',
    },
    AdminDumpLoadTableStatuses: {
        REQUESTED: 'Offert begärd',
        CONFIRMED: 'Offert mottagen',
        ORDERED: 'Order beställd',
        NOT_ORDERED: 'Order avböjd',
        IN_PROGRESS: 'Order pågående',
        DONE: 'Order avslutad',
    },
    InfoMessagesSochi: {
        ACTIVE_OFFER: 'Det finns ett aktivt erbjudande! Vänligen acceptera eller avböj erbjudandet.',
    },
    WeekSummary: {
        weekSummary: 'Veckoöversikt (Planerad)',
        weight: 'Vikt',
        transportations: 'Transporter',
    },
    TimeFilters: {
        DAY: 'Dag',
        WEEK: 'Vecka',
        MONTH: 'Månad',
        YEAR: 'År',
    },
    contaminationValues: {
        mrr: '<MRR',
        km: '>MRR <KM',
        mkm: '>KM <MKM',
        ifa: '>MKM <FA',
        fa: '>FA',
        inert: 'INERT',
        ifaLeaching: 'IFA',
        limit: 'FA',
        na: 'N/A',
        custom: 'Egen',
        select: 'Välj nivå',
    },
    DumploadSummarySection: {
        amount: 'Volym',
        startDate: 'Startdatum',
        Distance: 'Distans',
        Duration: 'Transporttid enkel väg',
        LoadFee: 'Mottagningsavgift/ton',
        InternalLoadFee: 'Mottagningsavgift/ton (intern)',
        trips: 'Transporter',
        landfillPrice: 'Mottagningspris',
        linkForSign: 'Signering',
        linkForSignDescription: 'Den här länken är avsedd för att underteckna en köporder.',
        withoutMatching: 'exkl. matchning',
        withMatching: 'inkl. matchning',
        inbound: 'Inkommande',
        outbound: 'Utgående',
    },
    AdminDestinationResultInfoCodes: {
        MATCHED_LANDFILLS_NOT_FOUND: 'Inga matchande mottagare kunde hittas.',
        TRANSPORT_CONF_EMPTY:
            'För närvarande kan systemet inte beräkna approximerade resultat eftersom transportkonfigurationen är ogiltig.',
        GOOGLE_SERVICE_UNAVAILABLE: 'Det går inte att beräkna avstånd. Googles tjänster är inte tillgängliga',
        LANDFILL_ROUTE_UNAVAILABLE: 'Det går inte att beräkna avstånd. Mottagare är oåtkomlig',
        AUTOMATIC_PRICES_UNAVAILABLE: 'This one should never appear',
        SKIP_MATCHING_MODE: 'Ingen matchning läge',

        DUMP_TYPE_EMPTY: 'För närvarande kan systemet inte beräkna approximerade resultat. Vänligen välj masstyp.',
        LOCATION_EMPTY: 'För närvarande kan systemet inte beräkna approximerade resultat. Vänligen välj projektplats.',
        NOT_ALLOCATED_TRIPS: 'Inte alla tilldelade massor.',
        OVER_ALLOCATED_TRIPS: 'Det finns över tilldelade massor.',
    },
    SochiDestinationResultInfoCodes: {
        MATCHED_LANDFILLS_NOT_FOUND:
            'Inga matchande mottagare kunde hittas. Vill ni bli kontaktade av en representant från Pinpointer för hjälp, klicka på "Begär offert" så kontaktar vi er.',
        TRANSPORT_CONF_EMPTY:
            'Inga matchande mottagare kunde hittas. Vill ni bli kontaktade av en representant från Pinpointer för hjälp, klicka på "Begär offert" så kontaktar vi er.',
        GOOGLE_SERVICE_UNAVAILABLE:
            'Inga matchande mottagare kunde hittas. Vill ni bli kontaktade av en representant från Pinpointer för hjälp, klicka på "Begär offert" så kontaktar vi er.',
        LANDFILL_ROUTE_UNAVAILABLE:
            'Inga matchande mottagare kunde hittas. Vill ni bli kontaktade av en representant från Pinpointer för hjälp, klicka på "Begär offert" så kontaktar vi er.',
        AUTOMATIC_PRICES_UNAVAILABLE:
            'Inga matchande mottagare kunde hittas. Vill ni bli kontaktade av en representant från Pinpointer för hjälp, klicka på "Begär offert" så kontaktar vi er.',
        SKIP_MATCHING_MODE: 'Ingen matchning läge',

        DUMP_TYPE_EMPTY: 'För närvarande kan systemet inte beräkna approximerade resultat. Vänligen välj masstyp.',
        LOCATION_EMPTY: 'För närvarande kan systemet inte beräkna approximerade resultat. Vänligen välj projektplats.',
        NOT_ALLOCATED_TRIPS:
            'Inte alla tilldelade massor. Om du vill bli kontaktad av en representant från Pinpointer för hjälp, klicka på "Begär offert" så kontaktar vi dig.',
        OVER_ALLOCATED_TRIPS:
            'Det finns över tilldelade massor. Om du vill bli kontaktad av en representant från Pinpointer för hjälp, klicka på "Begär offert" så kontaktar vi dig.',
    },
    ProjectStatus: {
        NEW: 'Nytt',
        IN_PROGRESS: 'Pågående',
        DONE: 'Avslutat',
        DELETED: 'Borttaget',
    },
    UserStatus: {
        REGISTERED: 'Väntar på att e-post skall bekräftas',
        EMAIL_CONFIRMED: 'Väntar på godkännande',
        ENABLED: 'Aktiverad',
        BLOCKED: 'Blockerad',
    },
    DriverStatus: {
        ACTIVE: 'Aktiva',
        ARCHIVED: 'Arkiverad',
        INACTIVE: 'Inaktiv',
    },
    UserRole: {
        [UserRole.EMPLOYEE]: 'Administratör',
        [UserRole.ADMIN]: 'Sys.Admin',
        [UserRole.EXTERNAL_RECEIVER]: 'Användare',
        [UserRole.DRIVER]: 'Chaufför',
        [UserRole.EXCAVATOR_OPERATOR]: 'Maskinist',
    },
    UserContextRole: {
        [UserContextRole.OWNER]: 'Ägare',
        [UserContextRole.PROJECT_USER]: 'Användare',
        [UserContextRole.RECEIVER]: 'Mottagare',
        [UserContextRole.GUEST]: 'Gäst',
        [UserContextRole.UNKNOWN]: 'Okänt',
        [UserContextRole.AUTHOR]: 'Författare',
        [UserContextRole.PROJECT_EXCAVATOR_OPERATOR]: 'Maskinist',
        [UserContextRole.PROJECT_DRIVER]: 'Förare',
    },
    DespatchAdviceType: {
        [DespatchAdviceType.ORIGINAL]: 'Original',
        [DespatchAdviceType.REPLACE]: 'Byta ut',
        [DespatchAdviceType.CANCELLATION]: 'Annullering',
    },
    DespatchAdviceResponseType: {
        [DespatchAdviceResponseType.ACCEPTED]: 'Accepterad',
        [DespatchAdviceResponseType.DECLINED]: 'Tackade nej',
    },
    DeliveryLineBeastStatus: {
        [BeastStatus.DISABLED]: 'Inaktiverad',
        [BeastStatus.NOT_SENT]: 'Inte skickat',
        [BeastStatus.SENT]: 'Skickat',
        [BeastStatus.ACCEPTED]: 'Accepterad',
        [BeastStatus.DECLINED]: 'Tackade nej',
    },
    OverviewTab: {
        amount: 'Volym',
        startDate: 'Startdatum',
        dumpType: 'Typ av Massa',
    },
    ToxicLimitErrorCodes: {
        equal: '{item1} kan inte motsvara {item2}',
        bigger: '{item1} kan inte vara större än {item2}',
        lesser: '{item1} kan inte vara mindre än {item2}',
    },
    DumploadPrice: {
        landfillPricePerTon: 'Mottagningsavgift kr/ton (pris till kund)',
        internalLandfillPricePerTon: 'Mottagningsavgift kr/ton (pris från mottagning)',
        forceZeroPrices: 'Använd nollpriser',
    },
    PROJECT: {
        startDate: 'Projektets startdatum',
        endDate: 'Projektets slutdatum',
        status: 'Projektstatus',
        location: {
            lat: 'Projektets latitud',
            lng: 'Projektets långitud',
        },
    },
    PROJECT_LOAD: {
        declaration: 'Deklaration av avfall (alla fält före fältet "Typ av avfall" måste fyllas i)',
        status: 'Status',
        dumpType: 'Typ av Massa',
        order: 'Beställning',
        amount: 'Volym',
        unitOfMeasure: 'Måttenhet',
        date: 'Startdatum',
        landfillPrice: 'Mottagningspris',
        projectId: 'Projektnummer',
        orderChangeTitle: 'Bekräfta orderändring',
        orderNewHasDeliveryLines: 'Ordern har redan leveransrader. Ersätt med vald ordning ändå?',
        orderOldHasDeliveryLines: 'Beställningen $0 har redan leveransrader. Ersätt denna beställning med vald ändå?',
        signStatus: 'Signeringsstatus',
        signAction: 'Skicka för signering',
        signErrorTitle: 'Fel',
        signStatusTitle: 'Status',
    },
    DeclarationOfWaste: {
        title: 'DEKLARATION AV AVFALL',
        serialNumber: 'Deklarationsnr',
        wasteProducer: 'Avfallsproducent',
        customerNumber: 'Kundnummer',
        invoice: 'Fakturamottagare',
        address: 'Adress',
        zipCode: 'Postnr',
        place: 'Ort',
        reference: 'Referens',
        phone: 'tel',
        organizationNr: 'Organisationsnr',
        ewcCode: 'Avfallskod',
        wasteType: 'Typ av avfall',
        pollutionType: 'Typ av förorening',
        process: 'Process där avfallet uppstått',
        reason: 'Anledning till avfall',
        consistency: 'Konsistense vid +5 grader',
        risks: 'Risker',
        liquid: 'Flytande',
        viscous: 'Trögflytande',
        solid: 'Fast',
        pasty: 'Pastöst',
        powder: 'Pulver',
        small: 'Småemb. I större emb.',
        other: 'Övrigt',
        toxic: 'Giftigt',
        flammable: 'Brandfarligt, flampunkt',
        acid: 'Surt pH',
        hazard: 'Skadligt',
        explosive: 'Explosivt, riskgr.',
        alk: 'Alk pH',
        harmful: 'Hälsofarligt',
        hazardous: 'Miljöfarligt',
        corrosive: 'Frätande',
        oxidising: 'Oxiderande',
        hygiene: 'AFS hyg. Gränsvärden, grp A eller B',
        stability: 'Stabilitet/Reaktivitet',
        wasteContains: 'Avfallet innehåller(t.ex. vatten, organisk förorening, halogener, alkalimetaller, svavel etc.)',
        subject: 'Ämne',
        value: 'Värde',
        unit: 'Enhet',
        totalWaste: 'Total mängd avfall',
        quantityPerDelivery: 'Mängd per leverans:(vid större partier)',
        tank: 'Lev i tankbil/slamsug',
        container: 'Lev i container/flak',
        rail: 'Lev med järnväg',
        ca: 'ca',
        grossTon: 'Ton brutto',
        pack: 'Förpackning',
        packaging: 'Emballage',
        quantity: 'Antal',
        transport: 'Transport',
        unNr: 'UN-nr',
        class: 'Klass',
        conveyor: 'Transportör',
        otherInfo: 'Övrig information',
        isAttachedProductInfo: 'Varuinfo bifogas',
        otherCar: 'Övriga bil st',
        date: 'Datum',
        wasteOriginate: 'Plats där avfallet uppstått',
        wasteOriginateMunicipality: 'Kommun där avfallet uppstått inklusive kommunkod',
        hasMassEffluentRecord:
            'a) Kommer massorna från en fastighet där utfyllnad, industriverksamhet eller saneringsarbeten förekommit, eller där det historiskt sett förekommit någon form av utsläpp?',
        hasContaminatedSoilGroundWater:
            'b) Finns det eller har det funnits avfallsupplag, kemikalieupplag, cisterner/tankar, oljeavskiljare eller ledningar i mark som kan ha förorenat mark och grundvatten?',
        hasDifferentSmellAndColor: 'c) Har massorna avvikande lukt och färg?',
        hasOccuredByExcavationOfPath:
            'd) Har massorna uppstått vid schaktning av bangård, banvallar eller vägbank vid väg?',
        hasPetroleumProductBeenOnSite: 'e) Har tankning av petroleumprodukter skett på platsen?',
        hasAnyAccidentsOccuredOnProperty: 'f) Har några olyckor inträffat på fastigheten ex spill, brand, explosion?',
        environmentalTechnicalOrOtherAssessments:
            'Om ni svarar ja på någon av de ovanstående frågor (a-f) ska en miljöteknisk bedömning eller annan förklaring bifogas som bekräftar att massorna är utan risk för negativ miljöpåverkan.\n' +
            'Följande bifogas:',
        hasEnvironmentalTechnicalAssessment: 'Miljöteknisk bedömning',
        hasOtherAssessment: 'Annan bedömning',
        massType: 'Typ av massor',
    },
    MapPage: {
        cannotResolveLocation: 'Kan inte hitta plats. Ändra inmatning eller försök senare',
        cannotModifyLocation: 'Projektet är aktivt, platsen kan inte ändras!',
        landfillStatusFilter: {
            ALL: 'Alla',
            OWN: 'Egen',
            EXTERNAL: 'Extern',
        },
    },
    PoiInfoPanel: {
        selectSiteOnTheMap: 'Välj mottagning på kartan för att förhandsgranska rutten',
        selectProjectOnTheMap: 'välj projektet på kartan för att förhandsgranska rutten',
        noProjectDumpLoads: 'Projektet innehåller inga beställningar',
        serialNumber: 'Order',
        landfill: 'Mottagare',
        noLandfillDumpLoads: 'Mottagarna har inga beställningar',
        projectName: 'Projekt',
        noOwnLandfills: 'Inga mottagare tillgängliga',
    },
    ProjectOffers: {
        declarationNumber: 'Deklarations nummer',
    },
    ReceiptModal: {
        scaleReceiptScan: 'Vågkvitto',
        orderNumber: 'Ordernummer',
        regNumber: 'Reg.nummer',
        date: 'Datum',
        weight: 'Vikt',
    },
    TransportSchedule: {
        editTransportSchedule: 'Ändra transportschema',
        startDate: 'Startdatum',
        endDate: 'Slutdatum',
        totalMass: 'Total massa',
        distributeEvenly: 'Fördela jämnt',
        distributeEvenlyConfirm: 'Detta kommer att fördela massorna jämnt över transportschemat. Fortsätt?',
        transportScheduleSaved: 'Transportschema sparat.',
    },
    settingsPage: {
        title: 'inställningar',
        featuresFlags: {
            title: 'Systemfunktioner',
            captchaEnabled: 'Captcha aktiverat',
            CAPTCHA: 'Captcha',
            ChatGPT: 'ChatGPT',
            Peppol: 'Peppol',
            Whatsapp: 'Whatsapp',
            active: 'Aktiva',
            notActive: 'Inte aktiv',
            enabled: 'Aktiverad',
            disabled: 'Inaktiverad',
        },
        transportConfig: {
            title: 'Transportunderlag',
            tonnsPerTruck: 'Ton  per lastbil',
            pricePerKm: 'Pris per km',
            pricePerHour: 'Pris per timme',
            tax: 'Marginal',
            fixedPrice: 'Fast pristillägg (kr)',
            co2perKm: 'CO2 utsläpp (kg/km)',
        },
        companyConfig: {
            title: 'Systemkonfiguration',
            orgName: 'Organisationsnamn',
            orgNumber: 'Organisationsnummer',
            place: 'Ort',
            postNumber: 'Postnr',
            address: 'Adress',
            tel: 'Telefon',
            email: 'E-post',
            cityPlace: 'Stad/Plats',
            contactPhoneNumber: 'Kontaktnummer',
            contactEmail: 'Kontakta E-post',
            version: 'Version',
        },
        keys: {
            title: 'Api-nycklar',
            captchaSiteKey: 'ReCaptcha (offentlig nyckel)',
            captchaSecretKey: 'ReCaptcha (hemlig nyckel)',
            chatGptApiKey: 'ChatGPT API nyckel',
            chattGptAssistantId: 'ChatGPT assistant id',
            tickstarClientId: 'Tickstar klient id',
            tickstarClientSecret: 'Tickstar klienthemlighet',
            tickstarToken: 'API-token för deltagarsökning',
            pinpointerGLN: 'Pinpointer GLN',
            testDespatchPartyOrgNumber: 'Despatch party organisationsnummer (endast test)',
            testConsigneeOrgNumber: 'Consignee organisationsnummer (endast test)',
            mytapiProductId: 'Mytapi product ID',
            mytapiToken: 'Mytapi token',
        },
        validation: {
            captchaKeysShouldBeFilled: 'Kan du fylla i Captcha-nycklarna om du vill aktivera den här funktionen',
            chatGptKeysShouldBeFilled: 'Kan du fylla i ChatGPT-nycklarna om du vill aktivera den här funktionen',
            noNegativeOrStringValue: 'Fältvärdet kan inte vara negativt eller sträng',
            peppolKeysShouldBeFilled: 'Kan du fylla i Peppol-nycklarna om du vill aktivera den här funktionen',
            mytapiKeysShouldBeFilled:
                'Kan du fylla i Mytapi produkt-ID och Token om du vill aktivera Whatsapp-funktionen',
        },
        navigationTabs: {
            companyTab: 'Företag',
            transportTab: 'Transport',
            apiTab: 'API',
            featuresTab: 'Funktioner',
        },
        customScripts: {
            customScripts: 'Anpassade skript',
            addCustomScript: 'Lägg till anpassat skript',
            editCustomScript: 'Redigera anpassat skript',
            text: 'text',
            src: 'src',
            async: 'async',
            active: 'Är aktiv',
            order: 'Order',
        },
        database: {
            database: 'Databas',
            databaseMaintenance: 'Databasunderhåll',
            recalculateProjectsSolidTestResults: 'Beräkna om solida testresultat i projekt',
            recalculateLandfillsSolidTestResults: 'Beräkna om solida testresultat i mottagare',
            recalculateProjectsSolidTestResultsConfirm:
                'Denna åtgärd kommer att räkna om fasta testresultat för alla aktiva massor (Offert begärd, Offert mottagen, Order beställd och Order pågående)',
            recalculateLandfillsSolidTestResultsConfirm:
                'Den här åtgärden kommer att räkna om solida testresultat för alla aktiva delområden',
            downloadRandomReceipts: 'Ladda ner slumpmässiga kvitton',
            randomReceiptsCountLabel: 'Hur många kvitton ska laddas ner?',
            testBugsnag: 'Test bugsnag',
        },
    },
    AccountPage: {
        account: 'Konto-ID',
        name: 'Namn',
        surname: 'Efternamn',
        email: 'E-post',
        phone: 'Telefon',
        password: 'Lösenord',
        passwordChanged: 'Lösenordet har ändrats',
        logoutMessage: 'Är du säker på att du vill logga ut?',
        oldPassword: 'Gammalt lösenord',
        newPassword: 'Nytt lösenord',
        repeatPassword: 'Upprepa nya lösenordet',
        validation: {
            nameEmpty: 'Fyll i namn',
            fieldMustFilled: 'Fältet måste fyllas i',
            passwordsMustMatch: 'lösenorden måste vara lika',
        },
    },
    CompaniesTable: {
        route: 'Företag',
        customerNumber: 'Kundnummer',
        companyName: 'Företagsnamn',
        orgNumber: 'Org.nummer',
        phone: 'Telefon',
        email: 'E-post',
        comments: 'Kommentarer',
        location: 'Plats',
        orgName: 'Org. namn',
        city: 'Stad',
        country: 'Land',
        contactPerson1: 'Kontaktperson #1',
        contactPerson2: 'Kontaktperson #2',
        phoneNumber: 'Telefonnummer #1',
        phoneNumber2: 'Telefonnummer #2',
        website: 'Hemsida',
        address: 'Fakturaadress',
        address2: 'Fakturaadress #2',
        zipCode: 'Postnummer',
        apiKey: 'API-nyckel',
        active: 'Aktiva',
        isTransportCompany: 'Transportör',
        generateAPIKey: 'Generera API-nyckel',
        generationSuccess: 'API-nyckel genererad',
        companySaveSuccess: 'Företaget har sparats',
        apiKeyIsClear: 'Api Clear har tagits bort',
        fortnoxStatus: 'Fortnox status',
        createCompany: 'Skapa företag',
        deleteConfirmation: 'Är du säker på att du vill ta bort företaget',
        canNotBeDeleted: '"$1" kan inte raderas',
        canNotBeDeletedDescription: 'Företaget kan inte raderas förrän det har aktiva anslutna objekt',
        connectedEntities: {
            title: 'Anslutna enheter',
            projects: 'Projekt ($1)',
            landfills: 'Mottagare ($1)',
            users: 'Användare ($1)',
            drivers: 'Förare ($1)',
        },
        saveBeforeAPIOperations: 'Spara företaget innan du utför någon operation med API-nyckeln',
        GLN: 'GLN',
    },
    CompanyCard: {
        orgNumber: 'Org.nummer',
        email: 'E-post',
        orgName: 'Org. namn',
        city: 'Stad',
        country: 'Land',
        contactPerson1: 'Kontaktperson #1',
        contactPerson2: 'Kontaktperson #2',
        phoneNumber: 'Telefonnummer #1',
        phoneNumber2: 'Telefonnummer #2',
        website: 'Hemsida',
        invalidEmail: 'E-post ogiltlig',
        organizationNumberRequired: 'Organisationsnumret kan inte vara tomt',
        nameRequired: 'Namnet kan inte vara tomt',
        tabs: {
            edit: 'Redigera',
            projects: 'Projekt',
            landfills: 'Mottagare',
            users: 'Användare',
        },
        drivers: 'Chaufförer',
        addDriver: 'Lägg till en Chaufför',
        GLN: 'GLN',
    },
    FileTemplatesPage: {
        tryAgain: 'Ett fel uppstod. Vänligen försök igen senare. Kontakta supporten om problemet kvarstår',
        declarationNotFound:
            'Filen hittades inte. Det finns inkonsekvens på servern. Vänligen kontakta support och ange aktuell webbadress och skärmdump',
    },
    info: 'info',
    DialogButtons: {
        ok: 'Okej',
        close: 'Stäng',
        confirm: 'Fortsätt',
        cancel: 'Avbryt',
    },
    fileUploaded: 'Filen laddades upp!',
    ProjectDocuments: {
        other: 'Andra dokument',
        weightReceipt: 'Vågkvitto',
        analysis: 'Analys',
        noDocumentationFound: 'Ingen dokumentation hittades',
        add: 'Lägg till',
        analysisDocuments: 'Analysdokument',
        documentName: 'Dokument namn',
        addAnalysis: 'Lägg till analys',
        files: 'Filer',
        declarationOfWaste: 'Avfallsdeklaration',
        weightReceiptTable: {
            project: 'Projekt',
            cantFetchWeightReceiptRightNow: 'Det går inte att hämta vågkvitton just nu.',
            transportedCompany: 'Transportör',
            declarationNumber: 'Order',
            customerInvoice: 'Fakturanummer',
            mass: 'Materialtyp',
            level: 'Förorening',
            comment: 'Beskrivning',
            notes: 'Egen anteckning',
            landfillName: 'Mottagningsanläggning',
            registrationNumber: 'Reg. nr.',
            truckCapacity: 'Lastkapacitet',
            loadPercentage: 'Fyllnadsgrad',
            date: 'Leveransdatum',
            weight: 'Mängd',
            distance: 'Sträcka enkel väg (km)',
            scaleReceipt: 'Vågkvitto',
            cost: 'Pris(SEK)',
            excavated: 'Påbörjad',
            loaded: 'Accepterad',
            delivered: 'Levererad',
            excavatorOperator: 'Maskinist',
            check: 'Egen kontroll',
            photo: 'Sitewatch',
            detach: 'Koppling Sitewatch',
            confirmDetach: 'Bekräfta lossa',
            photos: 'Foton',
            timestamp: 'Tidsstämpel',
        },
        buttons: {
            view: 'Visa',
            delete: 'Radera',
            showAll: 'Visa allt',
            download: 'Ladda ner',
            downloadAll: 'Ladda ner allt',
            downloadSelected: 'Ladda ner valt',
            downloadAllPhotos: 'Ladda ner alla foton',
            downloadSelectedPhotos: 'Ladda ner valda foton',
            exportToXlsx: 'Exportera till xlsx',
        },
        maximumFileSizeNote: 'Den högsta tillåtna filstorleken att ladda upp är 20MB!',
        ewc: 'EWC',
        documentType: {
            CERTIFICATE: 'Projektcertifikat',
            ANALYSIS: 'Massanalys',
        },
    },
    uploadReceipt: 'Ladda upp kvitto',
    receiptUploaded: 'Kvittot laddades upp',
    uploadFile: 'Ladda upp en fil',
    driverNotFound: 'Transportör hittades inte',
    reportSent: 'Rapporten har skickats',
    transportHasNotStartedYet: 'Transporten har inte startat än.',
    goToOffers: 'Gå till erbjudanden',
    continue: 'Fortsätt',
    updateProject: 'Uppdatera projektet',
    getStarted: 'Kom igång',
    addSite: 'Lägg till en ny plats',
    iSender: 'Jag är en avsändare',
    iSenderDescription: 'Jag har massor som jag vill skicka till mottagare.',
    iLandfill: 'Jag är en mottagare',
    iLandfillDescription: 'Jag har en mottagningsplats där jag vill ta emot inkommande massor.',
    incoming: 'Inkommande',
    masses: 'Massor',
    massesFieldHint: 'Vad är det för typ av material',
    classification: 'Klassning',
    projectHistory: 'Projekthistorik',
    transportSchedule: 'Transportschema',
    doesNotTransportScheduleYet: 'Har inget transportschema än',
    noTransportScheduleForFilters: 'Inget transportschema för valda filter',
    orderSchedule: '$1 transportschema',
    addMass: 'Lägg till ny massa',
    editMass: 'Redigera',
    noMasses: 'Inga massor ännu',
    deleteMass: 'Radera',
    rejectMass: 'Avvisa',
    confirmDelete: 'Bekräfta borttagning',
    confirmReject: 'Bekräfta avvisa',
    hereAdjustTransportSchedule: 'Justera transportschemat här.',
    requireToFinalizeOfferRequest: 'Denna funktion kommer snart...',
    requireAnalysisToOfferRequest:
        'Lägg till övriga dokument som ex. markundersökningar, medelvärdesberäkningar eller om du har flera analyser kopplade till samma massa.',
    getBackIn24hrs:
        'Transportprisberäkningen är baserad på tur- och returresa). Pinpointer återkommer inom 24 timmar efter att en begäran om en offert har mottagits.',
    totalMassesInT: 'Totala massor (Ton)',
    estimatedTotalTransportPerWeek: 'Uppskattat antal transporter (per vecka)',
    estimatedTotalInSek: 'Uppskattat totalpris (SEK)',
    file: 'Fil',
    files: 'Filer',
    addFile: 'Lägg till fil',
    deleteFileConfirm: 'Är du säker på att du vill ta bort den här filen?',
    editSchedule: 'Ändra schema',
    requestOffer: 'Begär offert',
    additionalDocuments: 'Bilagor',
    unit: 'Enhet',
    unitFieldHint: 'Enhet, är det ton eller kubikmeter',
    savedAsDraft: 'Massan sparades som utkast!',
    tooLargeFile: 'Den högsta tillåtna filstorleken att ladda upp är 20 MB!',

    massCategory: 'Masskategori',
    startDate: 'Startdatum',
    endDate: 'Slutdatum',
    seePriceEstimate: 'Se prisuppskattning',
    toxicAnalysisSochi: {
        head: {
            totalest: 'Totaltest ({})',
            laktest: 'Laktest ({})',
            forLevel: 'Pinpointer-standarden för {}',
        },
    },
    totalestTestingHead: 'Totalhalt (Pinpointer-standard för {})',
    laktestTestingHead: 'Lakning (Pinpointer standard för {})',
    clearValues: 'Rensa värden',
    priceEstimate: 'Prisuppskattning',
    priceEstimateExplaining: 'Enbart en uppskattning och slutgiltligt pris kan skiljas',
    selectMassType: 'Ange typ av material',
    estimatedCO2Emission: 'Uppskattade CO2-utsläpp',
    projectUpdatedSuccessfully: 'Projektet har uppdaterats',
    editProjectDetails: 'Redigera projektdetaljer',
    updatingProjectLocation: 'Uppdaterar projektplatsen, vänta ...',
    sent: 'Begärda',
    accepted: 'Bekräftad',
    declined: 'Avböjda',
    all: 'Alla',
    requested: 'Begärd',
    rejected: 'Avböjda',
    outgoing: 'Utgående',
    offers: 'Offerter',
    documentations: 'Dokumentation',
    documents: 'Dokument',
    projectDeliveries: 'Uppföljning',
    landfillDeliveries: 'Uppföljning',
    deviations: 'Avvikelser',
    projectInfo: 'Uppgifter',
    priceCalculator: 'Priskalkylator',
    dashboard: 'Instrumentbräda',
    admin: 'Admin',
    settings: 'Inställningar',
    signout: 'Logga ut',
    document: 'Dokumentera',
    documentType: 'Dokumenttyp',
    depositionContract: 'Deponeringskontrakt',
    mewabDocument: 'Övriga dokument',
    signed: 'Signerad',
    signAction: 'Signera',
    accept: 'Acceptera/Köp',
    decline: 'Avböj',
    documentsSigned: 'Dokument signerade',
    noMassSelected: 'Ingen massa vald!',
    massType: 'Masstyp',
    viewOffers: 'Visa förfrågningar',
    toProjectPage: 'Till projektet',
    yourOfferHasBeen: 'Din förfrågan har skickats!',
    offerModalNote: 'Tack! Pinpointer återkommer inom 24 timmar.\nUnder tiden kan du besöka projektsidan..',
    phoneNumber: 'Telefonnummer',
    phoneNumberIsRequired: 'Telefonnummer krävs!',
    requesting: 'Offertförfågan skickas.',
    contaminationLevels: 'Föroreningsnivåer',
    contaminationLevelsFieldHint: 'Föroreningsnivå på materialet',
    contaminationLevelsForDumpTypeUnavailable: 'Föroreningsnivåer för vald tipptyp kan inte specificeras',
    inspect: 'Visa',
    vehicle: 'Transporter',
    itLooksLikeThisProjectIsComplete: 'Det ser ut som att detta projekt är slutfört!',
    uploaded: 'Uppladdad',
    projectNoMass: 'Projektet innehåller ingen massa ännu!',
    numberShort: 'Nr',
    supportInfo: 'Ring 0771-67 10 10 om du behöver hjälp',
    loginScreen: {
        welcomeTo: 'Välkommen till',
        signInToContinue: 'Logga in för att fortsätta',
        notAMember: 'Inte medlem ännu?',
        join: 'Anslut er till',
        today: 'idag!',
    },
    priceCalculatorPage: {
        orderNumber: 'Ordernummer',
        declarationNumber: 'Deklarationsnummer',
        massType: 'Masstyp',
        classification: 'Klassning',
        description: 'Beskrivning',
        status: 'Status',
        landfillPricePerTon: 'Pris mottagning (SEK/Ton)',
        weight: 'Vikt (Ton)',
        vehicle: 'Transporter',
        analysis: 'Analys',
        add: 'Lägg till',
        edit: 'Redigera',
        notes: 'Anteckningar',
        openFiles: 'Öppna filer',
        option: 'Alternativ',
        noNotes: 'Det finns inga anteckningar här ännu',
        outboundMode: 'Bli av med',
        inboundMode: 'Ta emot',
        next: 'Nästa',
    },
    massDetails: {
        transportedTo: 'Mottagare',
        truckTransported: 'Antal transporter',
        declarationNumber: 'Deklarationsnr.',
    },
    projectStatuses: {
        NEW: 'Nytt',
        IN_PROGRESS: 'Pågående',
        DONE: 'Avslutat',
        DELETED: 'Borttaget',
    },
    user: {
        firstName: 'Förnamn',
        surName: 'Efternamn',
    },
    topBar: {
        project: 'Projekt',
        landfill: 'Mottagare',
    },
    featureNotReady: 'Den här funktionen är inte klar än, men kommer inom kort.',
    receipts: 'Kvitton',
    ProjectAddEditMass: {
        title: 'Specifikation ny massa',
        subtitle:
            'Vi matchar dig med lämpliga anläggningar och återkopplar till dig så fort vi har ett förslag, vår handledningstid är 1-2 arbetsdagar.',
        description: 'Beskrivning',
        selectPreferOption: 'Välj vad som är viktigast för ditt projekt',
        massDescription: 'Beskriv massan',
        materialType: 'Materialtyp',
        calendarInfoProjectEndDate: 'Projektets slutdatum: $1',
        withInvasiveSpecies: 'Innehåller invasiva arter',
        invasiveSpeciesDescription: 'Vilka invasiva arter?',
        invasiveSpeciesPlaceholder: 'T.ex. blomsterlupin, parkslide, vresros',
        withTOC: 'TOC är analyserat',
        withTOCHint: 'Totalt organiskt kol',
        TOCValue: 'TOC (halt i %)',
        dates: 'Transportdatum',
        datesHint: 'Under vilken tidsperiod ska massan transporteras bort?',
        amountPlaceholder: 'Ange mängd',
        over50cmFieldHint: 'Innehåller massan sten, betong eller block av annat material som är större än 50 cm?',
        stackabilityFieldHint: 'Går det att lägga massan i en hög med förhållandet 1:1 utan att den flyter ut?',
        newComment: 'Övrig information (lägg till adminkommentar)',
        lastComment: 'Sista kommentaren',
        changeProjectDateToMatchMassDate:
            'Slutdatumet för massan är senare än projektets. Vill du ändra projektets slutdatum till $1?',
        requestQuote: 'Begär offert direkt',
        requestQuoteHint: 'Checka ur den här ifall du vill begära offerten senare',
        landfillName: 'Namn mottagning',
        landfillAddress: 'Adress och ort till mottagning',
        matchWithPinpointer: 'Matcha med Pinpointer',
        matchWithPinpointerHint:
            'Matcha med Pinpointer innebär att Pinpointer föreslår lämplig avsättning. Har du redan avsättning och vill använda systemet till uppföljning, bocka ur rutan.',
    },
    ProjectPriceCalculator: {
        description: 'Beskrivning',
        downloadFile: 'Nedladdning fil',
    },
    dumpType: {
        __entityName: 'Typ av Massa',
        tonnsPerCubicM: 'Ton per m3',
        description: 'Beskrivning',
        name: 'Namn',
        usedInDeclaration: 'Använd i deklaration',
    },
    fileExists: 'En fil med samma namn finns redan!',
    declarationOfWasteComponent: {
        viewEdit: 'Visa/Redigera',
        view: 'Visa',
    },
    Deviation: {
        ticketNumber: 'Nr.',
        issue: 'Problem',
        issueFieldHint: 'Vad är problemet',
        description: 'Beskrivning',
        descriptionFieldHint: 'Beskriv problemet mer i detalj',
        suggestedSolution: 'Förslag till lösning',
        suggestedSolutionFieldHint: 'Har du en åtgärd som du kan föreslå redan nu',
        commentText: 'Kommentarstext',
        author: 'Skapat av',
        status: 'Status',
        dateSent: 'Skapades',
    },
    DeviationFilter: {
        DRAFT: 'Utkast',
        ACTIVE: 'Aktiva',
        RESOLVED: 'Avslutade',
    },
    deviationStatus: {
        [DeviationStatus.DRAFT]: 'Utkast',
        [DeviationStatus.REQUESTED]: 'Ny avvikelse',
        [DeviationStatus.ANSWERED]: 'Besvarad',
        [DeviationStatus.CLOSED]: 'Avslutad',
    },
    DeviationForm: {
        newDeviation: 'Ny avvikelse',
        deviation: 'Avvikelse',
        conversationHistory: 'Konversationshistorik',
        newComment: 'Ny kommentar',
        issueIsRequired: 'Problem krävs',
        descriptionIsRequired: 'Beskrivning krävs',
        commentIsRequired: 'Kommentar krävs',
        pleaseSendComment: 'Vänligen skriv en kommentar först',
        reopen: 'Återöppna',
        deviationUpdated: 'Avvikelsen har uppdaterats',
        deviationCreated: 'Ny avvikelse skapad',
        deviationClosed: 'Avvikelse avslutad',
        confirmDelete: 'Är du säker på att du vill ta bort den här filen?',
        toUpload: 'Ladda upp',
        uploaded: 'Uppladdad',
    },
    ProgressBar: {
        reservedForPinpointer: 'Tillgänglig kapacitet',
        current: 'Levererat',
        booked: 'Kommande',
        total: 'Totalt',
    },
    MultiSelect: {
        startTyping: 'Skriv här...',
        selected: 'valda',
    },
    Stepper: {
        stepNumber: 'Steg $1 av $2',
    },
    LandfillForm: {
        createLandfill: 'Skapa ny plats för mottagning',
        updateLandfill: 'Ändra grundläggande information',
        enterDetails: 'redigera uppgifter',
        next: 'Nästa',
        back: 'Tillbaka',
        siteName: 'Namn',
        chooseLandfillStatus: 'Status',
        chooseLandfillTypeFieldHint:
            'Vilken typ av plats är det som skall läggas upp. Tänk på att detta inte går att ändra i efterhand',
        gateFee: 'Avgift (SEK)',
        minMargin: 'Min/marg (SEK)',
        marginPercent: 'Marginal (%)',
        contactPerson: 'Kontaktperson',
        contactPersonFieldHint: 'Kontaktpersoner kan läsa all information, men inte lägga till eller ändra något',
        comment: 'Kontaktuppgifter',
        commentFieldHint: 'Gerell information om platsen, kontaktuppgifter och liknande',
        description: 'Beskrivning av särskilda kriterier',
        descriptionFieldHint: 'Finns det några särskillda rutiner, undantag, specialhantering eller liknande',
        openingHours: 'Öppettider',
        openingTime: 'Öppningstid',
        closingTime: 'Stängningstid',
        closed: 'Stängd',
        open24hrs: 'Öppet 24 timmar',
        landfillUpdatedSuccessfully: 'Mottagningsplatsen har uppdaterats',
        permit: 'Tillstånd',
        permitFieldHint: 'Ladda upp dokument med tex tillstånd och liknande',
        noTypeChangeAllowed: 'Ingen typändring tillåten för typ {0}',
        contactPeople: 'Kontaktpersoner',
        capacity: 'Kapacitet',
    },
    Materials: {
        material: 'Material',
        incoming: 'Öppen för mottagning',
        outgoing: 'Öppet för leverans',
        onHold: 'Stängd för mottagning',
        hold: 'Stoppa mottagning',
        resume: 'Återuppta mottagning',
        price: 'Pris',
        pricePlaceHolder: 'Pris (SEK/ton)',
        priceBase: 'Grundpris (SEK/ton)',
        priceBaseFull: 'Grundpris',
        hasPriceOver50cm: 'Får innehålla material större än 50 cm',
        hasPriceOver50cmHint: 'Massan får innehålla sten, betong eller block av annat material som är större än 50 cm',
        hasNonStackablePrice: 'Får vara ej stapelbart',
        hasNonStackablePriceHint:
            'Med stapelbart avses här att det går att lägga massan i en hög med förhållande 1:1 utan att den flyter ut',
        priceOver50cm: 'Pristillägg >50 cm (SEK/ton)',
        priceNotStackable: 'Pristillägg ej stapelbart (SEK/ton)',
        netto: 'Netto',
        brutto: 'Brutto',
        add: 'Lägg till',
        maxAmount: 'Maximal mängd som kan tas emot',
        currentAmount: 'Mottagen mängd',
        reservedAmount: 'Tillgänglig mängd',
        maxAmountDescription: 'Den totala mängden för detta material som du kan fylla din mottagningsplats med.',
        currentAmountDescription:
            'Den mängd material som du för närvarande har fyllt på med. Kom ihåg att uppdatera detta när du tar emot mer material från tredje part.',
        reservedAmountDescription: 'Tillgänglig mängd för detta material som du kan fylla din mottagningsplats med.',
        deliveredAmount: 'Levererad mängd',
        solidFaAllowed: 'Farligt avfall',
        solidFaAllowedFieldHint: 'Är det tillåtet att köra in FA på platsen',
        materialCategory: 'Materialtyp',
        materialCategoryFieldHint: 'Vad är det för typ av material',
        materialCategoryIsRequired: 'Materialkategori krävs',
        all: 'Alla material',
        externalId: 'Material ID',
        allowTOC: 'TOC (%)',
        allowTOCDescription: 'Totalt organiskt kol',
        TOCValue: 'TOC-värde',
        allowInvasiveSpecies: 'Får innehålla invasiva arter',
        invasiveSpeciesNotAllowed: 'Inga invasiva arter',
        invasiveSpecies: 'Description of invasive species',
        invasiveSpeciesPlaceHolder: 'T.ex. blomsterlupin, parkslide, vresros',
        description: 'Beskrivning',
        isProvider: 'Utgående',
        incomingTotal: 'Total mottagning',
        outgoingTotal: 'Total leverans',
    },
    transportHistory: {
        title: 'Transporthistorik',
        transportedCompany: 'Transportör',
        declarationNumber: 'Deklarationsnummer.',
        registrationNumber: 'Reg.Nr.',
        date: 'Datum',
        weight: 'Vikt (Ton)',
        weightReceipt: 'Vågkvitto',
        kgCo2: 'kg CO2',
        cost: 'Pris(SEK)',
        distanceKm: 'Avstånd (Km)',
        loadPercentage: 'Lastprocent',
    },
    openInNewDesign: 'Öppna i ny design',
    mustBeGreaterThanZero: '$1 måste vara större än noll',
    isRequired: '$1 krävs',
    noDataToDisplay: 'Ingen data att visa',
    Dates: {
        canNotBeBefore: 'Datumet kan inte infalla före den $1',
        canNotBeAfter: 'Datumet kan inte infalla efter den $1',
    },
    LandfillMaterialCard: {
        base: 'Grundpris',
        moreThan500: 'Tillägg för över 50cm',
        supplementNotStackable: 'Tillägg för ej stapelbart',
        netto: 'Netto',
        brutto: 'Brutto',
    },
    LandfillSchedule: {
        title: 'Transportschema',
    },
    ProjectDashboard: {
        serialNumber: 'Ordernummer',
        inbound: 'Inkommande',
        outbound: 'Utgående',
        noOperatorName: 'Ingen grävmaskinist',
        titleMode: 'Totalt ton inkommande/utgående',
        titleExcavatorOperator: 'Totalt ton per grävmaskinist',
        titleTruck: 'Totalt ton per lastbil',
        titleFillRate: 'Fyllnadsgrad',
        titleCO2Trucks: 'CO2-förbrukning av förare av lastbilar',
        titleCO2Machines: 'CO2-förbrukning av maskiner',
        titleCO2Total: 'Total CO2-förbrukning',
        fillRateLowest: 'Lägst',
        fillRateAverage: 'Genomsnitt',
        fillRateHighest: 'Högsta',
        fillRateTruckHeader: 'Lastbil',
        fillRateValueHeader: 'Genomsnitt, %',
        licensePlate: 'Registreringsskylt',
        operatorName: 'Maskinist',
        regNumber: 'Reg. nr.',
        trucks: 'Lastbilar',
        machines: 'Maskiner',
        CO2Table: {
            operators: 'Maskiner',
            drivers: 'Lastbilar',
            regNumber: 'Reg. nr.',
            vehicleType: 'Fordonstyp',
            engine: 'Motor',
            fuel: 'Bränsle',
            distance: 'Avstånd, km',
            hours: 'Timmar, h',
            CO2: 'CO2, kg',
            total: 'Total',
        },
    },
    Admin: {
        exit: 'Avsluta',
        dumpLoads: 'Order',
        landfills: 'Mottagningsplatser',
        projects: 'Projekt',
        orders: 'Volymer',
        deliveryLines: 'Leveranser',
        customers: 'Kunder',
        companies: 'Företag',
        drivers: 'Transportörer',
        dumpTypes: 'Typer av massor',
        beastMaterials: 'BEAst material',
        landfillStatuses: 'Status för mottagningsplats',
        substances: 'Ämnen',
        settings: 'Inställningar',
        toxicLimits: 'Gränsvärden',
        account: 'Konto',
        allDumpLoads: 'Alla massor',
        allLandfills: 'Alla mottagningsplatser',
        users: 'Användare',
        activity: 'Användaraktivitet',
        projectsPage: {
            activeProjects: 'Aktiva projekt',
            allProjects: 'Alla projekt',
        },
        landfillsPage: {
            activeLandfills: 'Aktiva mottagningsplatser',
            allLandfills: 'Alla mottagningsplatser',
            subareas: 'Delområden',
        },
        permissions: 'Behörigheter',
        locks: 'Lås',
        despatchAdviceResponses: 'Skicka svar på råd',
        vehicleTypes: 'Fordonstyper',
        timeSpent: 'Spenderad tid',
    },
    Table: {
        rowsPerPage: 'Rader per sida',
        prevPage: 'Föregående sida',
        nextPage: 'Nästa sida',
        of: 'av',
    },
    DumpLoadsTable: {
        orderNumber: 'OrderNr.',
        orderStatus: 'Orderstatus',
        projectName: 'Projektnamn',
        dumpType: 'Masstyp',
        customer: 'Kund',
        comments: 'Kommentarer',
        toxicAnalysis: 'Analys uppladdat',
        addToxicAnalysis: 'Lägg till',
        confirmDeleteToxicAnalysis: 'Är du säker på att du vill ta bort den här filen?',
        analysisApproved: 'Analys godkänd',
        analysisApprovalState: {
            APPROVED_INTERNALLY: 'Internt',
            APPROVED_EXTERNALLY: 'Externt',
            NOT_APPROVED: 'Ej godkänd',
        },
        receiver: 'Mottagare',
        deliveryPlan: 'Leveransplan',
        order: 'Kopplad order',
        landfillPricePerTon: 'Pris till kund (SEK / Ton)',
        amount: 'Planerad volym (Ton)',
        deliveredVolume: 'Levererad (Ton)',
        salesResponsible: 'Ansvarig säljare',
        selectDestination: 'Destination',
        noDestinationMessage: 'Det finns ingen deponi för denna dumpningslast',
        siteConfirmed: 'Mottagningsplatsen bekräftad',
        contractSigned: 'Kontrakt undertecknat',
        creditCheck: 'Kreditupplysning',
        editWasteDeclaration: 'Ändra',
        FANumber: 'Invägningsreferens',
        contaminationLevel: 'Föroreningsnivå',
        joinCode: 'Projektkod',
        mode: 'Läge',
        inbound: 'Inkommande',
        outbound: 'Utgående',
        outboundNoMatching: 'Ingen matchning',
    },
    DumpLoadCard: {
        dumpLoadInfo: 'Orderinformation',
        transportInfo: 'Transport',
        coordination: 'Samordning',
        orderNumber: 'OrderNr.',
        orderStatus: 'Orderstatus',
        projectName: 'Projektnamn',
        dumpType: 'Masstyp',
        customer: 'Kund',
        comment: 'Massbeskrivning',
        comments: 'Kommentarer',
        createdAt: 'Skapades',
        toxicAnalysis: 'Analys',
        analysisApproved: 'Intern mellangranskning',
        receiver: 'Mottagare',
        quoteSent: 'Offert skickad',
        landfillCost: 'Pris/ton (intern)',
        updatedAt: 'Senast uppdaterad',
        contractSigned: 'Kontrakt undertecknat',
        deliveryPlan: 'Leveransplan',
        order: 'Kopplad order',
        landfillPrice: 'Pris till kund',
        endDate: 'Planerat slutdatum',
        amount: 'Planerad volym',
        salesResponsible: 'Ansvarig säljare',
        toxicAnalysisApprovedExternally: 'Extern granskning',
        siteConfirmed: 'Mottagningsplatsen bekräftad',
        yourOrderNumber: 'Ditt beställningsnummer',
        creditCheck: 'Kreditupplysning',
        attachButton: 'Bifoga',
        clearButton: 'Klar',
        landfillOrdered: 'Mottagning beställd',
        FANumber: 'Invägningsreferens',
        statusWillChange: 'Orderstatus kommer att ändras till $1 [$2]',
        beastMaterial: 'BEAst material',
        mode: 'Läge',
        outbound: 'Utgående',
        inbound: 'Inkommande',
        matchWithPinpointer: 'Matcha med Pinpointer',
        suggestedLandfillName: 'Namn mottagning',
        suggestedLandfillAddress: 'Adress och ort till mottagning',
    },
    CommentsView: {
        comments: 'Kommentar',
        newCommentPlaceholder: 'Skriv din text här',
    },
    SortString: {
        ascending: 'A - Ö',
        descending: 'Ö - A',
    },
    SortLowHigh: {
        ascending: 'Lägsta - Högsta',
        descending: 'Högsta - Lägsta',
    },
    SortNumber: {
        ascending: '1 - 1000',
        descending: '1000 - 1',
    },
    SortDate: {
        ascending: 'Äldre överst',
        descending: 'Nyast överst',
    },
    OptionFilter: {
        selectAll: 'Välj alla',
        clearAll: 'Rensa alla',
        selected: 'Vald',
    },
    StringFilter: {
        includeEmptyPostfix: '(inkludera tom)',
        useIncludeEmpty: 'Inkludera tom',
    },
    ProjectsTable: {
        name: 'Projektnamn',
        status: 'Status',
        location: 'Plats',
        customer: 'Kund',
        comment: 'Kommentar',
        salesResponsible: 'Ansvarig säljare',
        edit: 'Ändra',
        deleteInfo: 'Ta bort projekt',
        deleteConfirmation: 'Är du säker på att du vill ta bort det här projekt?',
        restoreInfo: 'Återställ projekt',
        restoreConfirmation: 'Är du säker på att du vill återställa detta projekt?',
    },
    ProjectCard: {
        location: 'Projektplats',
    },
    LandfillsTable: {
        landfillName: 'Namn på mottagningsplats',
        location: 'Plats',
        openedAt: 'Öppettider',
        gateFee: 'Entré-/vågavgift',
        minMargin: 'Minsta marginal (kr)',
        deleteInfo: 'Ta bort leverans',
        deleteConfirmation: 'Är du säker på att du vill ta bort den här leveransen?',
        marginPercent: 'Marginal (%)',
        user: 'Användare',
        comments: 'Kommentarer',
        landfillDeletedMessage: 'Mottagningsplats raderad',
        capacity: 'Kapacitet',
    },
    SubareasTable: {
        landfillName: 'Deponi',
        landfillLocation: 'Plats',
        landfillReceivers: 'Kontaktperson',
        landfillStatus: 'Status',
        landfillComment: 'Deponi kommentarer',
        dumpType: 'Masstyp',
        unitOfMeasure: 'Måttenhet',
        comment: 'Delområdesbeskrivning',
        nonStackable: 'Ej staplingsbar',
        over50: 'Över 50',
        maxAmount: 'Kapacitet (Ton)',
        currentAmount: 'Använd kapacitet (Ton)',
        reservedAmount: 'Tillgänglig kapacitet (Ton)',
        landfillGateFee: 'Våg/Grind-avgift (SEK)',
        landfillMinMargin: 'Min marginal (SEK)',
        landfillMarginPercent: 'Marginal (%)',
        priceOver50cm: 'Över 50 extra',
        priceNotStackable: 'Ej stapelbar extra',
        grossPriceBase: 'Bruttopris',
        priceBase: 'Grundpris',
        priceUpdatedAt: 'Pris uppdaterat',
        externalId: 'Material ID',
        notOnHold: 'Öppet för mottagning',
        contaminationLevel: 'Föroreningsnivå',
        isProvider: 'Utgående',
        confirmDelete: 'Är du säker på att du vill ta bort det här materialet?',
        receiversMany: '{} och {} till',
    },
    OrdersTable: {
        projectName: 'Projektnamn',
        dumpType: 'Massa',
        route: 'Volymer',
        orderNumber: 'Ordernummer',
        documentNumber: 'Dokumentnummer',
        customer: 'Kund',
        status: 'Status',
        deliveryName: 'Mottagarnamn',
        deliveryAddress: 'Mottagningsplats',
        deliveryDate: 'Planerat Startdatum',
        endDate: 'Slutdatum',
        deliveryLineCount: 'Leveranser',
        deliveredVolume: 'Levererad volym (Ton)',
        salesResponsible: 'Ansvarig säljare',
        fortnoxStatus: 'Fortnox status',
        massCost: 'Kostnad massa (SEK)',
        massPrice: 'Pris massa (SEK)',
        receiver: 'Mottagare',
        noReceiverMessage: 'Det finns ingen mottagare för detta värdet',
        FANumber: 'FAnummer',
    },
    OrderCard: {
        header: 'Redigera beställning',
        status: 'Orderstatus',
        orderNumber: 'Ordernummer',
        documentNumber: 'Dokumentnummer',
        customer: 'Kund',
        startDate: 'Planerat Startdatum',
        deliveredVolume: 'Levererad volym',
        salesResponsible: 'Ansvarig säljare',
        deliveryAddress: 'Mottagningsplats',
        city: 'Stad',
        postalCode: 'Postnummer',
        country: 'Land',
        addressLine1: 'Adressrad 1',
        addressLine2: 'Adressrad 2',
        projectNumber: 'Projektnummer',
        projectName: 'Projektnamn',
        receiver: 'Mottagare',
        dumpType: 'Massa',
        lastComment: 'Sista kommentaren',
        landfillCost: 'Pris/ton (intern)',
        landfillCostExt: 'Cost/ton (externt)',
        plannedVolume: 'Planerad volym',
        FANumber: 'FAnummer',
    },
    OrderDeliveryLinesEditDialog: {
        title: 'Order $1',
    },
    OrderSyncDialog: {
        title: 'Synkronisering',
        subtitle: 'Synkronisera alla beställningar',
        orderSelectLabel: 'Order',
    },
    CompanySyncDialog: {
        title: 'Synkronisering',
        subtitle: 'Synkronisera alla företag',
        companySelectLabel: 'Kund',
    },
    FortnoxControls: {
        lastSynchronization: 'Senaste synkroniseringen',
        never: 'aldrig',
        status: 'Status',
        fetchStatusError: 'Fel vid hämtningsstatus',
        [SyncStatus.SUCCESS]: 'Framgång',
        [SyncStatus.ERROR]: 'Fel',
        [SyncStatus.UNKNOWN]: 'Okänd',
        completed: '{0} % klar',
        stop: 'Sluta',
    },
    statusOfOrder: {
        [OrderStatus.OPENED]: 'Öppen',
        [OrderStatus.CLOSED]: 'Stängd',
    },
    DeliveryLinesTable: {
        route: 'Leveranser',
        orderNumber: 'Ordernummer',
        number: 'Nummer',
        weight: 'Vikt (Ton)',
        totalWeightOnPage: 'Totalvikt på sidan',
        date: 'Datum',
        transportCompany: 'Transportör',
        truckRegistrationNumber: 'Reg.nummer',
        truckCapacity: 'Kapacitet (Ton)',
        loadPercentage: 'Lastprocent',
        customerInvoice: 'Fakturanr. Kund',
        transportInvoice: 'Fakturanr. Mottagning',
        landfillInvoice: 'Fakturanr. Mottagare',
        receipt: 'Vågkvitto',
        history: 'Historik',
        deletedAt: 'Raderas kl',
        deleteInfo: 'Ta bort leverans',
        deleteConfirmation: 'Är du säker på att du vill ta bort den här leveransen?',
        restoreInfo: 'Återställ leverans',
        restoreConfirmation: 'Är du säker på att du vill återställa denna leverans?',
        viewReceipt: 'Visa',
        downloadReceipt: 'Ladda ner',
        exportPdf: 'Exportera som pdf',
        replaceReceipt: 'Ersätt',
        replaceComplete: 'Kvitto uppdaterat',
        noPreviewForPdf: 'Förhandsgranskning för PDF inte tillgänglig',
        addButton: 'Lägg till leverans',
        title: 'Leveranser',
        verified: 'Verifierad',
        comment: 'Kommentar',
        completed: 'Avslutad',
        inProgress: 'Pågående',
        deleted: 'Raderade',
        saveBeforeOpenEditDialog: 'Spara data för att kunna redigera leveransrad',
        userNotes: 'Användaranteckningar',
        senderNotes: 'Användarens anteckningar',
        receiverNotes: 'Mottagarens anteckningar',
        status: 'Status',
        driver: 'Förare',
        excavatorOperator: 'Maskinist',
        beastStatus: 'BEAst status',
    },
    DeliveryLineCard: {
        orderNumber: 'Ordernummer',
        weight: 'Vikt',
        date: 'Datum',
        transportCompany: 'Transportör',
        truckRegistrationNumber: 'Reg.nummer',
        truckCapacity: 'Kapacitet',
        customerInvoice: 'Fakturanr. Kund',
        transportInvoice: 'Fakturanr. Mottagning',
        landfillInvoice: 'Fakturanr. Mottagare',
        receipt: 'Vågkvitto',
        verified: 'Verifierad',
        comment: 'Kommentar',
        createHeader: 'Lägg till leverans',
        updateHeader: 'Uppdatera leverans',
        completeHeader: 'Komplett leverans',
        vehicleType: 'Fordonstyp',
        fuel: 'Bränsle',
        engine: 'Motor',
    },
    DeliveryLineHistoryCard: {
        caption: 'Leveransrad',
        user: 'Användare',
        driver: 'Förare',
    },
    DeliveryLineStatuses: {
        [DeliveryLineStatus.EXCAVATED]: 'Utgrävda',
        [DeliveryLineStatus.DELIVERING]: 'Levererar',
        [DeliveryLineStatus.DELIVERED]: 'Levererad',
        [DeliveryLineStatus.VERIFIED]: 'Verifierad',
    },
    DespatchAdvicesTable: {
        title: 'Försändelseråd',
        category: 'Kategori',
        type: 'Typ',
        transactionId: 'Transaktions Id',
        status: 'Status',
        send: 'Skicka',
        sentAt: 'Skickades kl',
        noDataFound: 'Ingen information hittad',
        sendDisabledReason: 'Leveransråd har redan skickats',
        responseStatus: 'Svar status',
        responseInfo: 'Svar info',
    },
    LandfillStatuses: {
        createLandfillStatus: 'Skapa mottagarens status',
        editLandfillStatus: 'Ändra mottagarens status',
        chooseColor: 'Välj färg',
        confirmDeleteStatus: 'Är du säker på att du vill ta bort den här webbplatstypen?',
    },
    Substances: {
        createSubstance: 'Skapa nytt ämne',
        editSubstance: 'Redigera ämne',
        createSubstanceChild: 'Skapa nytt underämne',
        editSubstanceChild: 'Redigera underämne',
        substanceName: 'Ämnets namn',
        confirmDeleteSubstance: 'Är du säker på att du vill ta bort detta ämne?',
        addSubstance: 'Lägg till ämne',
    },
    DumpTypeTable: {
        title: 'Namn på massa',
    },
    DumpTypeCard: {
        id: 'Id',
        createTitle: 'Skapa typ av massa',
        editTitle: 'Ändra typ av massa',
        deleteTitle: 'Ta bort typ av massa',
        deleteConfirmation: 'Är du säker på att du vill ta bort typ av massa',
        tonnsPerCubicM: 'Ton per m3',
        description: 'Beskrivning',
        name: 'Namn på massa',
        usedInDeclaration: 'Använd i deklaration',
        contaminationLevelAvailable: 'Beräkna föroreningsnivån',
    },
    UsersTable: {
        route: 'Användare',
        name: 'Förnamn',
        lastName: 'Efternamn',
        phone: 'Telefon',
        email: 'E-post',
        role: 'Roll',
        status: 'Status',
        created: 'Skapades',
        deletedAt: 'Raderade',
        orgName: 'Organisationsnamn',
        orgNr: 'Organisationsnummer',
        customer: 'Kund',
        selectCustomer: 'Välj kund',
        comments: 'Kommentarer',
        userApproval: 'Användargodkännande',
        approveConfirmation: 'Är du säker på att du vill godkänna användare?',
        userBlocking: 'Användarblockering',
        blockingConfirmation: 'Är du säker på att du vill blockera användare?',
        userEnablement: 'Användaraktivering',
        enablementConfirmation: 'Är du säker på att du vill aktivera användare?',
        userDeletion: 'Ta bort användare',
        deletionConfirmation: 'Är du säker på att du vill ta bort användare:\n$1?',
        addUser: 'Ny användare',
        emailIsRequired: 'E-post krävs',
        invalidEmail: 'E-post ogiltlig',
        roleIsRequired: 'Roll krävs',
        customerIsRequired: 'Kund krävs',
        nameIsRequired: 'Namn krävs',
        surnameIsRequired: 'Efternamn krävs',
        lastActivityDate: 'Letzte Aktivität',
        active: 'Aktiva',
        deleted: 'Raderade',
        restoreInfo: 'Återställ användare',
        restoreConfirmation: 'Är du säker på att du vill återställa den här användaren?',
        saveSuccess: 'Användaren har sparats',
    },
    BlacklistPage: {
        title: 'Svartlistade e-postadresser',
        item: 'Svartlistad e-postadress',
        email: 'E-post',
        blacklist: 'Svartlista',
        addToBlacklist: 'Lägg till på svartlista',
        noDataFound: 'Ingen information hittad',
        deleteConfirmation: 'Är du säker på att du vill ta bort det här e-postmeddelandet från den svarta listan?',
    },
    DriversTable: {
        route: 'Transportörer',
        name: 'Namn',
        email: 'E-post',
        phone: 'Telefon',
        status: 'Status',
        registeredAt: 'Registrerings datum',
        other: 'Allt möjligt',
        lastComment: 'Sista kommentaren',
        lastActivityDate: 'Senaste aktivitet',
        deleteInfo: 'Ta bort föraren',
        deleteConfirmation: 'Är du säker på att du vill ta bort den här föraren?',
    },
    UserCard: {
        projects: {
            project: 'Projekt',
            role: 'Roll',
        },
        landfills: {
            landfill: 'Mottagare',
            role: 'Roll',
        },
    },
    UserActivityTable: {
        name: 'Namn',
        email: 'E-post',
        role: 'Roll',
        lastSession: 'Sista sessionen',
        active: 'Aktiv',
        minAgo: 'min. sedan',
    },
    ProjectForm: {
        projectInformation: 'Projektuppgifter',
        createProject: 'Skapa projekt',
        projectLabel: 'Projektnamn',
        projectName: 'Projektnamn...',
        startAndEndDates: 'Start- och slutdatum',
        startAndEndDatesFieldHint: 'Start- och slutdatum för projektet. Du kan ändra slutdatum senare',
        invoiceReference: 'Fakturamärkning',
        invoiceReferenceFieldHint: 'Fakturamnärkning, projektreferens eller liknande',
        comment: 'Kommentar',
        projectMembers: 'Projektmedlemmar',
        inviteViaEmail: 'eller bjud in via e-post',
        createNewExcavatorOperator: 'eller skapa en ny',
        invite: 'Inbjudan',
        inviteNewMember: 'Bjud in ny projektmedlem:',
        invalidEmail: 'Ogiltig e-postadress',
        inviteConfirmation: 'Obs: {0}. Vill du skicka en inbjudan?',
        inviteUserType: {
            unknown: 'person är inte medlem i PinPointer',
            sameCompany: 'denna person är medlem i ditt företag',
            anotherCompany: 'denna person är inte medlem i ditt företag',
        },
        saveBeforeInvite: 'Spara projektet innan du bjuder in användare',
        next: 'Nästa',
        selectUserModeTitle: 'Välj användartyp',
        addUser: 'Lägg till användare',
        drivers: 'Förare',
        contactPeople: 'Kontaktpersoner',
        excavatorOperators: 'Maskinister',
        joinCode: 'Projektkod',
        userInvited: 'Ett inbjudningsmail har skickats till användaren',
        confirmRemoveUser: 'Är du säker på att du vill ta bort $1 från projektet?',
        externalId: 'Externt id',
    },
    PermissionsPage: {
        project: 'Project',
        dumpLoad: 'DumpLoad',
        landfill: 'Landfill',
        user: 'User',
        deviation: 'Deviation',
        projectDeviations: 'Project deviations',
        landfillDeviations: 'Landfill deviations',
        role: 'Roll',
        action: 'Action',
        subject: 'Ämne',
        projectSubject: 'Project ämne',
        dumpLoadSubject: 'DumpLoad ämne',
    },
    globalSearch: {
        searchIn: 'Sök i $1...',
        orders: 'Order',
        drivers: 'Transportörer',
        companies: 'Företag',
    },
    noPreviewForPdf: 'Förhandsgranskning för PDF inte tillgänglig',
    copyrights: 'Upphovsrätt skyddas',
    emptyList: 'Tom lista',
    noDataForFilter: 'Ingen data hittades för det aktuella filtret',
    sortBy: 'Sortera efter',
    from: 'From',
    to: 'To',
    admin20: 'Admin 2.0',
    customerView: 'Kundvy projekt',
    setBooleanConfirm: 'Vill du ställa in "$1"?',
    unsetBooleanConfirm: 'Vill du avaktivera "$1"?',
    textCopied: 'Text kopierad till urklipp',
    noContentToCopy: 'Inget innehåll att kopiera',
    noLinkToFollow: 'Det finns ingen länk att följa',
    selected: 'Vald',
    select: 'Välj',
    reset: 'Återställ',
    showMore: 'Visa mer',
    localizationMode: 'Lokaliseringsläge',
    hideSupportChat: 'Göm supportchatt',
    showSupportChat: 'Visa supportchatt',
    downloadProgress: 'Laddade ner $1 av $2',

    LandfillReceiversSearch: {
        contactPersons: 'Kontaktpersoner',
        addContactPerson: 'Lägg till kontaktperson...',
    },
    unitsOfMeasure: {
        [UnitOfMeasure.TONNS]: 'ton',
        [UnitOfMeasure.CUBIC_METERS]: 'm3',
    },
    externalApi: {
        title: 'Integration',
    },

    projectLandfillReceiptsXlsx: {
        orderNumber: 'Order',
        comment: 'Egen anteckning',
        customerInvoiceNumber: 'Fakturanummer',
        plannedStartDateFmt: 'Leveransdatum',
        dumpType: 'Materialtyp',
        solidTestResult: 'Förorening',
        description: 'Beskrivning',
        projectName: 'Projekt',
        landfillName: 'Mottagningsanläggning',
        excavatorOperatorName: 'Maskinist',
        truckRegistrationNumber: 'Reg. nr.',
        excavatedDateFmt: 'Påbörjad',
        startDateFmt: 'Accepterad',
        endDateFmt: 'Levererad',
        truckCapacity: 'Lastkapacitet',
        weight: 'Mängd',
        loadPercentage: 'Fyllnadsgrad',
        distance: 'Sträcka enkel väg (km)',
        receiverCheck: 'Egen kontroll',
    },

    lock: {
        entityLocked: 'Entitet "$1" med id "$2" låstes av "$3" för $4 sedan',
        removeAllLocks: 'Ta bort alla lås',
        allLockRemoveConfirm: 'Är du säker på att du vill ta bort alla lås?',
    },

    mandatoryFields: {
        startDate: 'Startdatum',
        endDate: 'Slutdatum',
        location: 'Plats',
        dumpType: 'Typ av Massa',
        amount: 'Volym',
        unitOfMeasure: 'Måttenhet',
        date: 'Startdatum',
        priceData: 'Pris',
        creditCheck: 'Kreditupplysning',
        toxicAnalysisApprovedExternally: 'Extern granskning',
        contractSigned: 'Kontrakt undertecknat',
        siteConfirmed: 'Mottagningsplatsen bekräftad',
        landfillOrdered: 'Mottagning beställd',
        beastMaterialId: 'BEAst material Id',
    },
    ToxicLimitsPage: {
        solidLimits: 'Särskild gräns',
        leachingLimits: 'Laktest',
        faSumRules: 'FA summa regler',
        confirmDeleteSumRule: 'Är du säker på att du vill ta bort denna summaregel?',
        ruleName: 'Regelnamn',
        faSumLevel: 'FA summa nivå',
        threshold: 'Tröskelnivå',
        substanceName: 'Ämnesnamn',
        noSubstances: 'Inga ämnen än...',
        addNewRule: 'Lägg till ny summaregel',
        addSubstance: 'Tillsätt ämne',
    },
    LandfillPage: {
        addSubarea: 'Lägg till material',
        editSubarea: 'Redigera material',
        documents: 'Dokument',
        upload: 'Ladda upp',
    },
    ReleaseNotes: {
        releaseNotes: 'Release notes',
        createReleaseNote: 'Skapa release note',
        editReleaseNote: 'Redigera release note',
        releaseDate: 'Utgivningsdatum',
        markdownText: 'Markdown text',
        preview: 'Förhandsgranska',
        deleteConfirmation: 'Är du säker på att du vill ta bort release note',
        adminNotes: 'Admins notes',
        customerNotes: 'Customers notes',
        driverNotes: 'Drivers notes',
        whatsNew: 'Vad är nytt?',
    },
    ChatGpt: {
        tabTitle: 'AI',
        inputPlaceholder: 'Fråga någonting...',
        disclaimer:
            'Observera att jag är en AI-chatbot utvecklad av Pinpointer. Jag tillhandahåller generella råd och tips inom bygg- och miljöteknik baserade på den begränsade information jag har tränats på. Min rådgivning bör inte betraktas som eller ersätta juridisk eller teknisk rådgivning och bör alltid granskas kritiskt och kompletteras med professionell rådgivning vid behov.',
    },
    BeastMaterialsPage: {
        confirmDelete: 'Är du säker på att du vill ta bort detta BEAst-material?',
        create: 'Skapa BEAst-material',
        edit: 'Redigera BEAst-material',
        name: 'Namn',
        beastId: 'BEAst Id',
    },
    Excavator: {
        menu: {
            orders: 'Order',
            deliveries: 'Leveranser',
            users: 'Användare',
        },
        orders: {
            title: 'Tillgängliga beställningar',
            receiver: 'Mottagare',
            emptyList: 'Inga tillgängliga beställningar för grävning',
            direction: 'Riktning',
            inbound: 'Inkommande',
        },
        joinProject: {
            joinToProject: 'Projektkod',
            enterCode: 'Ange 6-siffrig kod för att gå med i projektet',
            successJoin: 'Du gick med i Project',
            failedJoin: 'Du kan inte gå med i något projekt med den här koden. Kontakta supporten för hjälp',
        },
        createDelivery: {
            title: 'Skapa ny utgrävning',
            truckNumber: 'Lastbilens registreringsnummer',
            truckNumberRequired: 'Lastbilens registreringsnummer krävs',
            successCreate: 'Ny leverans startad',
            noDriversInProject: 'Det finns inga lastbilschaufförer tilldelade projektet',
        },
        deliveries: {
            serialNumber: 'Deklaration nr.',
            date: 'Datum',
            dumpType: 'Massa',
            comment: 'Beskrivning',
            landfill: 'Mottagare',
            truckNumber: 'Reg.nummer',
            truckCapacity: 'Kapacitet (Ton)',
            weight: 'Vikt (Ton)',
            loadPercent: 'Lastprocent',
            distance: 'Distans',
        },
        deliveryUpdate: {
            deliveryAccepted: 'Leveransrad för order $1 accepterad av lastbil $2',
            deliveryDeclined: 'Leveransrad för order $1 avböjd av lastbil $2',
        },
        users: {
            title: 'Användare i projektet',
            projectName: 'Projektnamn',
            userName: 'Användare namn',
            truckNumber: 'Reg.nummer',
            phoneNumber: 'Telefonnummer',
            email: 'E-mail',
            status: 'Status',
            role: 'Roll',
        },
    },

    LandfillDeliveryEvents: {
        title: 'Odistribuerade leveranshändelser (magic pole)',
        date: 'Datum',
        licensePlate: 'Registreringsskylt',
        photo: 'Foto',
        selectDeliveryLine: 'Välj leverans att bifoga',
        deleteConfirm: 'Är du säker på att du vill ta bort den här händelsen?',
        attachToLine: 'Koppla',
        successAttach: 'Leveranshändelse kopplad till leveranslinjen',
        successDelete: 'Leveranshändelse raderad',
    },

    DriverState: {
        delivering: 'Levererar',
        free: 'Ledig',
    },
    DespatchAdviceResponses: {
        date: 'Datum',
        responseType: 'Svarstyp',
        declineReason: 'Avböja anledning',
        loadNewResponses: 'Ladda nya svar',
        noResponsesReceived: 'Inga nya svar inkom',
        numberResponsesReceived: '$1 nya svar mottagna!',
    },
    DespatchAdviceSettingsDialog: {
        title: 'Inställningar för Despatch Advice',
        consignee: 'Consignee',
        despatchParty: 'Despatch party',
        buyer: 'Köpare',
        seller: 'Säljare',
        shouldBeSpecified: 'Bör specificeras',
        connected: 'Ansluten till PEPPOL',
        notConnected: 'Inte ansluten till PEPPOL',
        despatchPartyNote: 'Pinpointer-företag kommer att användas om det inte anges',
        settings: 'PEPPOL-inställningar',
        pricePerTon: 'Pris/ton',
    },
    DespatchAdviceCategory: {
        [DespatchAdviceCategory.EXCAVATING]: 'Gräver',
        [DespatchAdviceCategory.TRANSPORTING]: 'Transporterar',
        [DespatchAdviceCategory.DUMPING]: 'Dumpning',
    },

    ProjectWhatsapp: {
        enableWhatsapp: 'Aktivera whatsapp',
        openGroup: 'Öppen grupp',
        enableWhatsappForProject: 'Aktivera whatsapp för projekt',
        enableWhatsappConfirm:
            'Alla projektmedlemmar kommer att få ett e-postmeddelande med inbjudningslänken. Bekräfta?',
        groupDescription:
            'Grupp för kommunikation kring frågor relaterade till Pinpointer-projektet "$1". Projektlänk: $2',
    },
    VehicleType: {
        id: 'Id',
        createTitle: 'Skapa fordonstyp',
        editTitle: 'Ändra fordonstyp',
        deleteTitle: 'Ta bort fordonstyp',
        deleteConfirmation: 'Är du säker på att du vill ta bort fordonstyp',
        name: 'Namn',
        kind: 'Typ',
        externalId: 'Externt ID',
        fuelConsumptions: 'Bränsleförbrukning',
        perKm: 'per km',
        perHour: 'per timme',
    },

    TimeSpent: {
        dumpLoadNumber: 'Order',
        user: 'Användare',
        licensePlate: 'Registreringsnummer',
        date: 'Datum',
        hoursSpent: 'Timmar spenderade',
        comment: 'Kommentar',
    },

    appStore: 'iPhone (App Store)',
    googlePlay: 'Android (Google Play)',
};

export default sv;
